import {FC, useContext, useEffect, useState} from 'react';
import {Alert, Box, Grid, Stack, Table, TableBody, TableRow, Typography} from '@mui/material';
import {useParams} from 'react-router';
import useFetchBooks from 'Hooks/useFetchBooks'
import DefaultButton from 'Components/atom/Buttons/DefaultButton';
import useCalendar, {TWeeks} from 'Hooks/useCalendar';
import {MessageContext} from 'Components/MessageContext';
import BookCell from 'Components/comm/BookCell';
import BackdropLording from 'Components/comm/BackDrop';

type ICurriculumParam = {
    year: string
    month: string
}

const Books: FC = () => {
    const {year, month} = useParams() as ICurriculumParam

    const {
        loading,
        fetchRequest,
        curriculums,
        calendarDate,
        setCalendarDate,
        handleCalendarNavigation
    } = useFetchBooks(year, month)
    const {createCurriculumCalendar} = useCalendar()
    const [weeks, setWeeks] = useState<TWeeks>([[]])
    const [bookCount, setBookCount] = useState<number>(0)
    const messageContext = useContext(MessageContext)
    const weekDay = ['one', 'two', 'three', 'four', 'five', 'six']

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => fetchRequest(), [setCalendarDate])
    useEffect(() => {
        if (curriculums.length > 0) {
            messageContext.handleSetMessage('カリキュラムを読み込みました。')

            setWeeks(createCurriculumCalendar(curriculums))
            setBookCount(() => {
                const c = curriculums.filter(value => value.lecture_id > 0)

                return c.length
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curriculums])


    if (loading) return <><BackdropLording showBackdrop={loading}/></>


    return (
        <>
            <Box sx={{my: 3}}>
                <Grid container alignItems="center">
                    <Grid item xs={12} sm={9} sx={{textAlign:{xs:'center', md:'left'},mt:{xs:'1em',sm:0}}}>
                        <Typography variant="h5">
                            {calendarDate.getFullYear()}年{Number(calendarDate.getMonth()) + 1}月 予約状況
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{display:{md:'flex'},mt:{md: 0, xs: 2}, mb: {md: 0, xs: 2}}} sm={3} justifyContent="center">
                        <Stack justifyContent={{xs:'center',md:'flex-end'}} direction="row" alignItems="right"
                               spacing={3}>
                            <DefaultButton name="pre" onClick={()=>handleCalendarNavigation('down')} label="前月"/>

                            <DefaultButton name="next" onClick={()=> handleCalendarNavigation('up')} label="翌月"/>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            {bookCount === 0 && (
                <Alert severity="info">カリキュラムが登録されていません。
                </Alert>
            )}
            {bookCount > 0 && (
                <Table sx={{width: '100%', border: '1px solid #eee', borderSpacing: '0'}}>
                    <TableBody>
                    {weeks.map((week,key) => (
                        <TableRow key={`row-${weekDay[key]}`}>
                            {week.map((day) => (

                                <BookCell key={`${day.lecture_date.getMonth()}-${day.lecture_date.getDate()}`} curriculum={day} currentMonth={calendarDate.getMonth()}/>

                            ))}
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>)}
        </>
    )

}
export default Books
