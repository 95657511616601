import React, {FC,} from 'react';
import {Paper, styled, Typography} from '@mui/material';
import {useNavigate} from 'react-router';
import useToken from "Hooks/useToken";
import DefaultButton from "Components/atom/Buttons/DefaultButton";
import useFetchHome from "Hooks/useFetchHome";
import BackdropLording from "Components/comm/BackDrop";
import DailyBar from "Components/Organisms/DailyBar";

const SBox = styled(Paper)(() => ({
    textAlign: 'center',
    minWidth: '80%',
    padding: 50,
    margin: '1em'
}));


const Home: FC = () => {
    const today = new Date()
    const navigate = useNavigate();
    const handleGotoPage = (url: string) => navigate(url)
    const {getToken} = useToken()
    const {isLoading, curriculums} = useFetchHome()

    if (!getToken()) {
        handleGotoPage('/login')
    }

    if (isLoading) return <><BackdropLording showBackdrop={isLoading}/></>

    return (
        <>
            <Typography variant="h5">ダッシュボード</Typography>
            <hr/>
            <SBox>
                <Typography variant="h5">予約状況</Typography>
                {curriculums.length > 0 && <DailyBar curriculums={curriculums}/>}
                <Typography sx={{textAlign: "right", my: '1rem'}}>
                    <DefaultButton label="予約管理"
                                   onClick={() => handleGotoPage(`/books/${today.getFullYear()}/${today.getMonth() + 1}`)}/>
                </Typography>
            </SBox>

            <SBox>
                <Typography variant="h5">利用者管理</Typography>
                <Typography sx={{textAlign: "right", my: '1rem'}}>
                    <DefaultButton label="利用者一覧" onClick={() => handleGotoPage('/students')}/>
                </Typography>
            </SBox>


        </>
    )
}
export default Home