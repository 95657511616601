/* eslint-disable */
import {useEffect, useMemo, useState} from 'react';
import {AxiosError} from 'axios';
import {SelectChangeEvent} from '@mui/material';
import {ICurriculum, ICurriculums, ILectureMaster} from 'Types/Response';
import useHttp from "Hooks/useHttp";
import useCalendar, {TWeeks} from "Hooks/useCalendar";
import {useError} from "Components/ErrorContext";

interface IResponse {
	lectureMaster: Array<ILectureMaster> | undefined
	curriculums: Array<ICurriculum>
	error: AxiosError | null
	weeks: TWeeks
	loading: boolean
}

interface IPostCurriculum {
	lecture_date: string
	lecture_id: number
	title: string
	url: string
}


interface IPostCurriculums {
	curriculums: IPostCurriculum[]
}

const useFetchCurriculums = () => {
	const {setContextError} = useError()
	const [response, setResponse] = useState<IResponse>({
		lectureMaster: undefined,
		curriculums: [],
		weeks: [],
		error: null,
		loading: false
	})
	const [calendarDate, setCalendarDate] = useState<Date>(new Date())
	const {createCurriculumCalendar} = useCalendar()
	const {client} = useHttp()
	const formatDate = (currentDatetime: Date) => `${currentDatetime.getFullYear()}-${currentDatetime.getMonth() + 1}-${currentDatetime.getDate()}`

	const handleError = useMemo(() => (reason: AxiosError) => {
		setResponse(prevState => ({...prevState, loading: false}))
		if (reason?.response?.status === 401) {
			setContextError(401, '再ログインが必要です')
		}
		if (reason?.response?.status === 500) {
			setContextError(500, 'サーバに不具合が起きています')
		}
	}, [setContextError])

	const fetchRequest = () => {
		setResponse(prevState => ({...prevState, loading: true}))
		const month = calendarDate.getMonth() + 1
		const c = client()
		c.get<ICurriculums | null>(`curriculums/books/${calendarDate.getFullYear()}/${month}`)
			.then((res) => {
				const curriculums: Array<ICurriculum> = []
				res.data?.curriculums.forEach(value => {
					curriculums.push({...value, lecture_date: new Date(value.lecture_date)})
				})

				setResponse({
					lectureMaster: res.data?.lectureMaster,
					curriculums,
					weeks: createCurriculumCalendar(curriculums),
					error: null,
					loading: false
				})
				// setWeeks(createCurriculumCalendar(curriculums))

			})
			.catch((error: AxiosError) => {
				handleError(error)
				// setResponse({... error:error,loading:false})

			})
	}


	const handleCalendarUP = () => {
		const currentDate = calendarDate
		currentDate.setMonth(currentDate.getMonth() + 1)
		setCalendarDate(currentDate)
		fetchRequest()
	}
	const handleCalendarDown = () => {
		const currentDate = calendarDate
		currentDate.setMonth(currentDate.getMonth() - 1)
		setCalendarDate(currentDate)
		fetchRequest()
	}

	const handleChange = (key: number, event: SelectChangeEvent, col: 'title' | 'lecture_id' = 'lecture_id') => {
		const responseNw = response.curriculums?.map((curriculum, index) => (
			index === key ? Object.assign(curriculum, {[col]: event.target.value}) : curriculum
		))
		setResponse(prevState => ({...prevState, response: responseNw}))
	}
	//TODO テストを書く
	const handlePostRequest = () => {
		if (response.curriculums !== undefined) {
			setResponse(prevState => ({...prevState, loading: true}))
			const post: IPostCurriculums = {curriculums: []};

			// eslint-disable-next-line array-callback-return
			response.curriculums?.map(value => {
				post.curriculums.push({...value, lecture_date: formatDate(value.lecture_date)})
			})
			// c.post<ICurriculums<string> | null>('
			// update',{curriculums:[{id:1,lecture_date:'2021-12-29',lecture_id:0}]})
			const c = client()
			c.put<ICurriculums | null>('curriculums/' +
				'update', post)
				.then(() => {
					fetchRequest()
				})
				.catch((error: AxiosError) => {
					handleError(error)

				})

		}
	}
	useEffect(() => {
		if (response.curriculums.length > 0) {
			setResponse(prevState => ({...prevState, weeks: createCurriculumCalendar(prevState.curriculums)}))
		}
	}, [response.curriculums])

	return {
		...response,
		fetchRequest,
		setResponse,
		calendarDate,
		handleChange,
		handlePostRequest,
		handleCalendarUP,
		handleCalendarDown
	}
};
export default useFetchCurriculums;