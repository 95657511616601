import {useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {useNavigate} from "react-router-dom";
import useHttp from 'Hooks/useHttp';
import {TAtendanceCardStudent} from 'Types/TStudent';
import {ICurriculum} from 'Types/AttendancesResponse';
import {useError} from "Components/ErrorContext";

export interface IAtendanceDetail {
    curriculum: ICurriculum | undefined,
    students: TAtendanceCardStudent[]
    isLoading: boolean;
    error: AxiosError | null;

}

type TPostUpdate = {
    attendanceId: number
    startTime: string
    endTime: string

}
const useFetchUserAtendance = (curriculumId: number) => {
    const {setContextError} = useError()
    const [response, setResponse] = useState<IAtendanceDetail>({
        isLoading: true,
        students: [],
        curriculum: undefined,
        error: null
    })
    const {client} = useHttp()
    const navigate = useNavigate()

    const handleError = (reason: AxiosError) => {
        setResponse(prevState => ({...prevState, isLoading: false}))
        if (reason?.response?.status === 401) {
            setContextError(401,'再ログインが必要です')
        }
        if (reason?.response?.status === 500) {
            setContextError(500,'サーバに不具合が起きています')
        }
    }

    const fetchRequest = (requestId: number) => {
        setResponse(prevState => ({...prevState, loading: true}))
        const c = client()
        c.get<IAtendanceDetail | null>(`at/detail/${requestId}`)
            .then((res) => {
                const students = res.data?.students?.map(at => ({
                    studentId: Number(at.studentId),
                    attendanceId: Number(at.attendanceId),
                    name: at.name,
                    startTime: at.startTime ? new Date(at.startTime) : new Date(0),
                    endTime: at.endTime ? new Date(at.endTime) : new Date(0),
                    interview: at.interview ? at.interview : '',
                    commentStaffId: at.commentStaffId ? Number(at.commentStaffId) : 0,
                    status: at.status ? at.status : '',
                    isLoading: false

                }))
                let lectureDate = new Date()
                if (res?.data?.curriculum) lectureDate = new Date(res.data?.curriculum?.lecture_date)
                const curriculum = {...res.data?.curriculum, lecture_date: lectureDate}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setResponse(prevState => ({
                    ...prevState,
                    curriculum,
                    students,
                    isLoading: false
                }))
            })
            .catch((error: AxiosError) => {
                setResponse(prevState => ({...prevState, isLoading: false}))
                if (error?.response?.status === 401) {
                    navigate('/login', {state: {userMessage: '再ログインが必要です。'}})
                }
                if (error?.response?.status === 500) {
                    navigate('/error', {state: {userMessage: 'サーバに不具合が起きています。'}})
                }
            })
    }

    const handleUpdateRequest = (postData: TPostUpdate) => {

        const c = client()
        c.put<{ attendance: TAtendanceCardStudent, message: string }>(`at/update`, postData)
            .then((res) => {
                // console.log(res)
                setResponse(prevState => ({
                    ...prevState,
                    students: response.students.map(value => value.attendanceId !== postData.attendanceId ? value : {
                        ...value,
                        status: res.data.attendance.status,
                        isLoading: false
                    })
                }))
            })
            .catch((reason: AxiosError) => {
                    handleError(reason)
                }
            )
    }
    const handleUpdateTime = (mode: 'update' | 'reset', studentId: number, key: 'startTime' | 'endTime', dateValue = new Date(0)) => {
        let timeValue: Date = dateValue
        const c = response.curriculum?.lecture_date
        if (mode === 'update' && !timeValue) {
            const now = new Date();
            if (c) timeValue = new Date(c.getFullYear(), c.getMonth(), c.getDate(), now.getHours(), now.getMinutes(), 0)
        } else if (timeValue && c && mode === 'update') {
            timeValue.setFullYear(c.getFullYear())
            timeValue.setMonth(c.getMonth())
            timeValue.setDate(c.getDate())

        }

        setResponse(prevState => ({
            ...prevState,
            students: response.students.map(value => value.studentId === studentId ? {
                ...value,
                [key]: timeValue,
                isLoading: true,
            } : value)
        }))
    }
    useEffect(() => {
            const postData: TPostUpdate[] = response.students
                .filter(value => (value.isLoading)).map(val => ({
                    attendanceId: val.attendanceId,
                    startTime: val.startTime.toJSON(),
                    endTime: val.endTime.toJSON()
                }))

            if (postData.length === 1) handleUpdateRequest(postData[0])
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [response.students])

    // const handleErrorClear = () => {

    //     setErrorMessage({hasErr: false, text: ''})
    // }

    useEffect(() => {
        if (curriculumId > 0) fetchRequest(curriculumId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        ...response,
        setResponse,
        handleUpdateTime,
        fetchRequest,
        handleUpdateRequest,
    }
};

export default useFetchUserAtendance;