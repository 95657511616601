import {ICurriculum} from '../Types/Response';

const useCurriculum = ()=> {

  const emptyCurriculum = () =>{
    const curriculum:ICurriculum = {
      id:Math.random().toString(),
      lecture_date: new Date(),
      lecture_id:0,
      is_time:0,
      name:'',
      title:'',
      url:'',
      service_limit:0,
      books:[],


    }

    return curriculum
  }
  const newCurriculum = (targetDate:Date) =>{
    const curriculum: ICurriculum = emptyCurriculum();

    return {...curriculum,lecture_date:targetDate}
  }

  return {newCurriculum}
}

export default useCurriculum