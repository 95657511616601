import {useState} from 'react';
import {AxiosError} from 'axios';
import {TAbsentCardStudent} from 'Types/TStudent';
import {ICurriculum} from 'Types/AttendancesResponse';
import {TOffice} from "Types/TOffice";
import useHttp from "Hooks/useHttp";
import {useError} from "Components/ErrorContext";

export interface IAbsent {
    curriculum: ICurriculum | undefined,
    student: TAbsentCardStudent | undefined
    office:TOffice|undefined
    isLoading: boolean;
    error: AxiosError | null;
    isRedirect:boolean

}

/**
 * Get時のレスポンス
 */
export type IAbsentResponse ={
    curriculum: ICurriculum | undefined,
    student: TAbsentCardStudent | undefined
    office:TOffice|undefined

}

/**
 * 更新時
 */
export type TAbsentPost = {
    reason: string
    staffId: string
    contactDate: Date
    contactPerson: string
    selfNote: string
    staffNote: string
    nextTime: string

}
/**
 *出席情報をやり取りする
 * @param attendanceId {number} 出席情報ID
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useFetchUserAbsent = (attendanceId: number) => {
    const {setContextError} = useError()
    const [response, setResponse] = useState<IAbsent>({
        isLoading: true,
        student: undefined,
        curriculum: undefined,
        office: undefined,
        error: null,
        isRedirect:false
    })
    const {client} = useHttp()

    /**
     * エラーハンドル
     * @param reason {AxiosError}
     */
    const handleError = (reason: AxiosError) => {
        setResponse(prevState => ({...prevState, isLoading: false}))
        if (reason?.response?.status === 401) {
            setContextError(401,'再ログインが必要です')
        }
        if (reason?.response?.status === 500) {
            setContextError(500,'サーバに不具合が起きています')
        }
    }


    /**
     *出席情報を取得
     * @param requestId {number} 出席情報ID
     */
    const fetchRequest = (requestId: number) => {
        setResponse(prevState => ({...prevState, loading: true}))

        const c = client()
        c.get<IAbsentResponse | null>(`absent/${requestId}`)
            .then((res) => {
                const student= {
                    studentId: Number(res?.data?.student?.studentId),
                    attendanceId: Number(res?.data?.student?.attendanceId),
                    name: res?.data?.student?.name,
                    reason: res?.data?.student?.reason ? res?.data.student.reason : '',
                    staffId: res?.data?.student?.staffId ? res?.data.student.staffId : '',
                    contactDate: res?.data?.student?.contactDate ? new Date(res.data.student.contactDate) : new Date(),
                    contactPerson: res?.data?.student?.contactPerson ? res.data.student.contactPerson : '',
                    selfNote: res?.data?.student?.selfNote ? res.data.student.selfNote : '',
                    staffNote: res?.data?.student?.staffNote ? res.data.student.staffNote : '',
                    nextTime: res?.data?.student?.nextTime ? res.data.student.nextTime : '',
                    isLoading: false
                }

                let lectureDate = new Date()
                if (res?.data?.curriculum) lectureDate = new Date(res.data?.curriculum?.lecture_date)
                const curriculum = {...res.data?.curriculum, lecture_date: lectureDate}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setResponse(prevState => ({
                    ...prevState,
                    curriculum,
                    student,
                    office:res?.data?.office,
                    isLoading: false
                }))
            })
            .catch((error: AxiosError) => {
                handleError(error)
            })
    }

    /**
     * 出席情報を更新する
     * @param postData {TAbsentPost} 更新情報
     */
    const handleUpdateRequest = (postData: TAbsentPost) => {
        if(!response.student) return
        const newStudent:TAbsentCardStudent = {...response.student,
            contactDate:postData.contactDate,
            contactPerson:postData.contactPerson,
            reason:postData.reason,
            staffId:postData.staffId,
            selfNote:postData.selfNote,
            staffNote:postData.staffNote,
            nextTime:postData.nextTime,
        }
        setResponse(prevState => ({...prevState, isLoading: true}))


        const c = client()
        c.put<{  message: string }>(`absent/update/${response.student.attendanceId}`, newStudent)
            .then(() => {
                setResponse(prevState => ({...prevState,
                    error: null,
                    isLoading: false,
                    isRedirect:true
                }))

            })
            .catch((reason: AxiosError) => {
                    handleError(reason)
                }
            )
    }
    /**
     * 出席情報をキャンセルする
     */
    const handleCancelRequest = () => {
        setResponse(prevState => ({...prevState, isLoading: true}))
        const id =response.student?.attendanceId
        const c = client()
        if(id) void c.put<{  message: string }>(`absentCancel/${id}`).then(() => setResponse(prevState => ({...prevState, isLoading: false,isRedirect:true}))
        )

    }



    return {
        ...response,
        setResponse,
        fetchRequest,
        handleUpdateRequest,
        handleCancelRequest
    }
};

export default useFetchUserAbsent;