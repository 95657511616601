import { useState } from "react";
import { AxiosError } from "axios";
import { ICurriculum, SystemMessage } from "Types/Response";
import useHttp from "Hooks/useHttp";
import useHandleError from "./useHandleError";

interface IResponse {
  curriculums: Array<ICurriculum>;
  error: AxiosError | null;
  loading: boolean;
  systemMessage: SystemMessage | undefined;
}

interface ICurriculums {
  curriculums: ICurriculum[];
}

interface IPostCurriculum {
  lecture_date: string,
  lecture_id: number,
  title: string,
  url: string,
}


interface IPostCurriculums {
  curriculums: IPostCurriculum[];
}


const useFetchBooks = (year: string, month: string) => {
  const { client } = useHttp();
  const [response, setResponse] = useState<IResponse>({
    curriculums: [],
    error: null,
    loading: false,
    systemMessage: undefined
  });
  const [calendarDate, setCalendarDate] = useState<Date>(new Date(`${year}/${month}/01`));
  const formatDate = (currentDatetime: Date) => `${currentDatetime.getFullYear()}-${currentDatetime.getMonth() + 1}-${currentDatetime.getDate()}`;
  const { handleError } = useHandleError();

  const fetchRequest = () => {
    setResponse(prevState => ({ ...prevState, loading: true }));
    const m = calendarDate.getMonth() + 1;
    const c = client();
    c.get<ICurriculums | null>(`curriculums/books/${calendarDate.getFullYear()}/${m}`)
      .then((res) => {
        const curriculums: Array<ICurriculum> = [];
        res.data?.curriculums?.forEach(value => {
          curriculums.push({ ...value, lecture_date: new Date(value.lecture_date) });
        });
        setResponse(prevState => ({
          ...prevState,
          curriculums,
          error: null,
          loading: false
        }));
      })
      .catch((error: AxiosError) => {
        setResponse(prevState => ({ ...prevState, isLoading: false }));
        handleError(error);
      });
  };


  const handleCalendarNavigation = (direction: "up" | "down"): void=> {
    const currentDate = calendarDate;
    const newMonth = direction === "up" ? currentDate.getMonth() + 1 : currentDate.getMonth() - 1;
    currentDate.setMonth(newMonth);
    setCalendarDate(currentDate);
    fetchRequest();
  };

  const handlePostRequest = () => {
    const c = client();

    if (response.curriculums !== undefined) {
      setResponse(prevState => ({ ...prevState, loading: true }));
      const post: IPostCurriculums = { curriculums: [] };

      // eslint-disable-next-line array-callback-return
      response.curriculums?.map(value => {
        post.curriculums.push({ ...value, lecture_date: formatDate(value.lecture_date) });
      });
      c.post<ICurriculums | null>("curriculums/update", post)
        .then(() => {
          fetchRequest();
        })
        .catch((error: AxiosError) => {
          setResponse(prevState => ({ ...prevState, isLoading: false }));
          handleError(error);

        });
    }
  };

  return {
    ...response,
    fetchRequest,
    calendarDate,
    setCalendarDate,
    handlePostRequest,
    handleCalendarNavigation
  };
};
export default useFetchBooks;