import React, {FC} from 'react';
import useFetchStudents from 'Hooks/useFetchStudents'
import {Fab, Grid, Typography} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {useNavigate} from 'react-router';
import BackdropLording from 'Components/comm/BackDrop';
import StudentSearch from 'Components/Organisms/StudentSearch';

const Students: FC = () => {
    const {students, setStudents, isLoading} = useFetchStudents()
    const navigate = useNavigate()
    const today = new Date()
    const handleNewUser = () => {
        navigate('/student/0')
    }

    if (isLoading) return <><BackdropLording showBackdrop={isLoading}/></>

    return (

        <>
            <Grid container alignItems="center">
                <Grid item xs={10}>
                    <Typography
                        variant="h5">利用者一覧</Typography>
                </Grid>


            </Grid>
            <StudentSearch students={students || []} studentsUpdate={setStudents} button={{
                label: "予約状況",
                func: id => navigate(`/student/books/${id}/${today.getFullYear()}/${today.getMonth() + 1}`)
            }}/>
            <Grid container>
                <Grid sx={{position: 'fixed', bottom: 60, right: 20,}}>

                    <Fab aria-label="add">
                        <AddIcon onClick={handleNewUser}/>
                    </Fab>
                </Grid></Grid>
        </>
    )


}
export default Students