import { useContext, useState } from "react";
import {AxiosError} from "axios";
import useHttp from "Hooks/useHttp";
import useHandleError from "./useHandleError";
import { MessageContext } from "../Components/MessageContext";

interface IPostCurriculumFile {
	curriculum_id: number
	file:File
}
interface IPostResponse {
	curriculum_id:string
	url:string
	file_name:string
}
interface ICurriculumFile {
	name:string
	url:string
	isUpload:boolean
}

const useFileUpload = ()=>{
	const [curriculumFile,setCurriculumFile]=useState<ICurriculumFile>({name:'',url:'',isUpload:false})
	const {client} = useHttp()
	const {handleError} = useHandleError()
	const messageContext = useContext(MessageContext)


	const handleCurriculumFilePost = (post:IPostCurriculumFile) => {
		setCurriculumFile({...curriculumFile,isUpload:true})
		const c = client()
		const formData = new FormData()
		formData.append('curriculum_id',post.curriculum_id.toString())
		formData.append('file',post.file)
		c.post<IPostResponse | null>('/curriculums/fileUpload', formData)
			.then((res) =>{
				if(res.data)	setCurriculumFile({name:res.data?.file_name,url:res.data.url,isUpload:false})
			})
			.catch((error: AxiosError) => {
				setCurriculumFile({name:'',url:'',isUpload:false})
				if (error?.response?.status === 422) {
					messageContext.handleSetMessage( 'PDFファイルではないか、容量が大きいため、アップロードできませんでした。','warning')
				}else {
					handleError(error)
				}
			})
	}
	const handleCurriculumFileDelete = (curriculumId:string) => {
		setCurriculumFile({...curriculumFile,isUpload:true})
		const c = client()
		c.delete<IPostResponse | null>(`curriculums/uploadDelete/${curriculumId}`)
			.then((res) =>{
				if(res.data)	setCurriculumFile({name:'',url:'',isUpload:false})
			})
			.catch((error: AxiosError) => {
				handleError(error)

			})
	}

	return {handleCurriculumFilePost,handleCurriculumFileDelete,curriculumFile}
}

export default useFileUpload