import {FC} from "react";
import EditIcon from '@mui/icons-material/Edit';
import {Link} from "react-router-dom";
import {Avatar, Stack, styled} from "@mui/material";
import {blue, red} from "@mui/material/colors";
import { TClassStudent} from "Types/TStudent";
import Box from "Components/atom/Box";
import SecondButton from "Components/atom/Buttons/SecondButton";
import {ButtonProp} from "Types/ButtonProp";

type Props = {
    student: TClassStudent
    button: ButtonProp | undefined

}

const UserBox = styled(Box)({
    width: '100%',
    height: '100%'
});

const StudentInfo: FC<Props> = ({student, button}) => <>
        <UserBox>
            <Avatar sx={{
                margin: 'auto',
                backgroundColor: student.sex === '1' ? red['300'] : blue['300']
            }}>{student.name.substring(0, 1)}</Avatar>
            <p>  {student.name}</p>

            {button &&
                <p><SecondButton name={`studentButton${student.id}`} onClick={() => button.func(student.id)} label={button.label}/></p>
            }
                <Stack justifyContent="right" direction="row"><Link aria-label={`studentLink${student.id}`} to={`/student/${student.id}`}><Avatar
                    sx={{width: "1.5em", height: "1.5em"}}><EditIcon/></Avatar></Link></Stack>
        </UserBox>
    </>
export default StudentInfo