import React, {FC} from "react";
import {Button, styled} from "@mui/material";
import {deepOrange} from '@mui/material/colors';
import {BaseButtonProps} from './DefaultButton';

const SButton = styled(Button)({
    textAlign: 'center',
    padding: '0.5em 2em',
    color: '#fff',
    borderRadius: 10,
    backgroundColor: deepOrange['600'],
    '&:hover': {
        backgroundColor: deepOrange['500']
    }
});
const ThirdButton: FC<BaseButtonProps> = ({label, onClick, type,disabled,name}) => {
    let newName = name;
    if (name === undefined) newName = `button`;

    return <SButton onClick={onClick} name={newName} aria-label={newName}
             disabled={disabled} type={type}>{label}</SButton>

}
export default ThirdButton
