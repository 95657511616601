import {Grid, Stack, Typography} from "@mui/material";
import {FC, useContext, useEffect} from "react";
import CurriculumCell from "Components/comm/CurritulumCell";
import {MessageContext} from "Components/MessageContext";
import BackdropLording from "Components/comm/BackDrop";
import {ICurriculum} from 'Types/Response';
import useFetchCurriculums from "Hooks/useFetchCurriculums";
import Box from "Components/atom/Box";
import DefaultButton from "Components/atom/Buttons/DefaultButton";


const Curriculums: FC = () => {
    const {
        loading,
        weeks,
        fetchRequest,
        curriculums,
        setResponse,
        calendarDate,
        lectureMaster,
        handlePostRequest,
        handleCalendarDown,
        handleCalendarUP
    } = useFetchCurriculums()
    const messageContext = useContext(MessageContext)
    const weekDay = ['one', 'two', 'three', 'four', 'five', 'six']
    const handleChangeCurriculums = (curriculum: ICurriculum) => {
        const newCurriculums = curriculums.concat()
        newCurriculums[curriculum.lecture_date.getDate() - 1] = curriculum
        setResponse(prevState => ({...prevState, curriculums: newCurriculums}))
    }


    useEffect(() => {
        fetchRequest()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (loading) messageContext.handleSetMessage('')

    }, [loading, messageContext])

    if (loading) return <><BackdropLording showBackdrop={loading}/></>


    return (
        <>
            <Box sx={{mb:'3rem'}}>
                <Grid container>
                    <Grid item xs={12} md={6} sx={{textAlign: {xs: 'center', md: 'left'}, my:{xs:2, md:0}}}>
                        <Typography variant="h5">
                            {calendarDate.getFullYear()}年{Number(calendarDate.getMonth()) + 1}月 カリキュラム
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} justifyContent="center" sx={{my:{xs:2, md:0}}}>
                        <Stack justifyContent={{xs:'center',md:'flex-end'}} direction="row" alignItems="right"
                               spacing={3}>

                            <DefaultButton name="preMonth" onClick={handleCalendarDown} label="前月"/>

                            <DefaultButton name="nextMonth" onClick={handleCalendarUP} label="翌月"/>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <table style={{width: '100%', border: '1px solid #eee', borderSpacing: '0'}}>
                <tbody>
                {weeks.map((week, key) =>
                    <tr key={weekDay[key]}>
                        {week.map((day) =>
                            <CurriculumCell
                                key={`${day.lecture_date.getMonth()}${day.lecture_date.getDate()}`}
                                curriculum={day}
                                handleChangeCurriculums={handleChangeCurriculums}
                                lectureMaster={lectureMaster}
                                currentMonth={calendarDate.getMonth()}/>
                        )}
                    </tr>
                )}
                </tbody>
            </table>

            <Grid container alignItems="center">
                <Grid item xs={12} sx={{my: 2, textAlign: 'center'}}>

                    <DefaultButton onClick={handlePostRequest} label="保存"/>
                </Grid>
            </Grid>
        </>
    )

};

export default Curriculums