import React, {FC, useState} from "react";
import {
    CircularProgress,
    Grid,
    MenuItem,
    Paper,
    styled,
    TextField,
    Typography
} from "@mui/material";
import {TAtendanceCardStudent} from "Types/TStudent";
import {TStaff} from "Types/TOffice";
import DefaultButton from "../atom/Buttons/DefaultButton";

type Props = {
    student: TAtendanceCardStudent
    staffs: TStaff[]
    updateFunction: (studentId: number, commentStaffId: string, interview: string) => void
}
const SBox = styled(Paper)({
    padding: '2em',
    margin: '1em 0',
    width: '100%'
})
const STextField = styled(TextField)({
    width: '100%'
})

const HearingCard: FC<Props> = ({student, staffs, updateFunction}) => {
    const [commentStaffId, setCommentStaffId] = useState<string>(student.commentStaffId === 0 ? "0" : student.commentStaffId.toString())
    const [interview, setInterview] = useState<string>(student.interview)
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setInterview(event.target.value)
    // const handleChangeStaff = (event: SelectChangeEvent) => {
    //     if (event.target.value) setCommentStaffId(event.target.value)
    // }
    const cardUpdate = () => updateFunction(student.studentId, commentStaffId, interview)

    if(student && staffs) return <SBox>
        <Typography variant="h4">{student.name}様</Typography>
        {student.isLoading ? <Typography sx={{textAlign: "center", my: "2em"}}><CircularProgress/></Typography> :
            <Grid container alignItems="center">
                <Grid item xs={1}>
                    <Typography>記入者</Typography>
                </Grid>
                <Grid item xs={11}>
                    <STextField sx={{width: '100%', fontSize: "80%", my: 2}}
                                id={`staffBox${student.studentId}`}
                                key={student.studentId}
                                name={`${student.name}`}
                                defaultValue={commentStaffId || '0'}
                                inputProps={
                                    {
                                        "aria-label": `select${student.studentId}`,
                                        "data-testid": `select${student.studentId}`,
                                    }
                                }
                                onChange={event => setCommentStaffId(event.target.value)}
                                select
                    >
                        <MenuItem key="empty"
                                  value="0"/>
                        {staffs?.map((staff) => (
                            <MenuItem key={staff.id}

                                      value={staff.id.toString()}>{staff.name}</MenuItem>
                        ))}
                    </STextField>
                </Grid>
                <Grid item xs={1}>
                    <Typography>コメント</Typography>
                </Grid>


                <Grid item xs={11}>
                    <STextField
                        id={`commentBox${student.studentId}`}
                        multiline
                        rows="5"
                        value={interview}
                        inputProps={
                            {"aria-label": `comment${student.studentId}`,}
                        }
                        onBlur={() => cardUpdate()}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>}
        <Typography sx={{textAlign: "right", my: 2}}><DefaultButton aria-label={`button${student.studentId}`} label="保存"
                                                                    type="button"
                                                                    name={`button${student.studentId}`}
                                                                    disabled={student.interview === interview && student.commentStaffId === parseInt(commentStaffId, 10)}
                                                                    onClick={() => cardUpdate()}/>
        </Typography>
    </SBox>

    return <></>
}

export default HearingCard