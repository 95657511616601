import {FC, useEffect, useState} from "react";

interface TimerProps {
    sec: number;
    setNum: (prevNum: number) => void;
}

const TimerA: FC<TimerProps> = ({sec, setNum}) => {
    const [resetSec, setResetSec] = useState<number>(sec);

    useEffect(() => {
        let timerId: NodeJS.Timeout | null = null;
        if (sec > 0) {
            timerId = setTimeout(() => setNum(sec - 1), 1000);
        }

        return () => {
            if (timerId !== null) {
                clearTimeout(timerId);
            }
        };
    }, [sec, setNum]);

    const handleResetClick = () => {
        setNum(resetSec);
    };

    return (
        <div>
            <div>残り時間: {sec >= 0 ? sec : 0}</div>
            <p>
                <input type="number" value={resetSec} onChange={(e) => setResetSec(parseInt(e.target.value, 10))}/>
                <button type="button" onClick={handleResetClick}>リセット</button>
            </p>
        </div>
    );
};
export default TimerA