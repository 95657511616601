import {useMemo, useState} from "react";
import {AxiosError} from "axios";
import {ICurriculum, SystemMessage} from "Types/AttendancesResponse";
import useHttp from "Hooks/useHttp";
import {useError} from "Components/ErrorContext";

interface IResponse {
    curriculums: Array<ICurriculum>
    error: AxiosError | null;
    loading: boolean;
    systemMessage: SystemMessage | undefined
}

interface ICurriculums {
    curriculums: ICurriculum[]
}

interface IPostCurriculum {
    lecture_date: string,
    lecture_id: number,
    title: string,
    url: string,
}

interface IPostCurriculums {
    curriculums: IPostCurriculum[]
}


const useFetchAttendances = (year: string, month: string) => {
    const {setContextError} = useError()
    const {client} = useHttp()
    const [response, setResponse] = useState<IResponse>({
        curriculums: [],
        error: null,
        loading: false,
        systemMessage: undefined
    })
    const [calendarDate, setCalendarDate] = useState<Date>(new Date(`${year}/${month}/01`))
    const formatDate = (currentDatetime: Date) => `${currentDatetime.getFullYear()}-${currentDatetime.getMonth() + 1}-${currentDatetime.getDate()}`;

    const handleError = useMemo(() => (reason: AxiosError) => {
        setResponse(prevState => ({...prevState, loading: false}))
        if (reason?.response?.status === 401) {
            setContextError(401, '再ログインが必要です')
        }
        if (reason?.response?.status === 500) {
            setContextError(500, 'サーバに不具合が起きています')
        }
    }, [setContextError])

    const fetchRequest = () => {
        setResponse(prevState => ({...prevState, loading: true}))
        const m = calendarDate.getMonth() + 1
        const c = client()
        c.get<ICurriculums | null>(`at/${calendarDate.getFullYear()}/${m}`)
            .then((res) => {
                    // eslint-disable-next-line
                // @ts-ignore
                const curriculums:ICurriculum[] = res.data?.curriculums?.map(value => ({
                    id:value.id,
                    lecture_date: new Date(value.lecture_date),
                    lecture_id: value.lecture_id,
                    is_time:value.is_time,
                    name: value.name,
                    title: value.title,
                    url: "",
                    attendances:value.attendances.map(at => ({
                        studentId:Number(at.studentId),
                        attendanceId:1,
                        name:at.name,
                        startTime: at.startTime ? new Date(at.startTime) : new Date(0),
                        endTime: at.endTime ? new Date(at.endTime) : new Date(0),
                        interview: at.interview ? at.interview : '',
                        commentStaffId: at.commentStaffId ? Number(at.commentStaffId) : 0,
                        isLoading: false

                    }))
                }))


                setResponse(prevState => ({
                    ...prevState,
                    curriculums,
                    error: null,
                    loading: false
                }))
            })
            .catch((error: AxiosError) => {
                handleError(error)
            })
    }


    const handleCalendarUP = () => {
        const currentDate = calendarDate;
        currentDate.setMonth(currentDate.getMonth() + 1)
        setCalendarDate(currentDate)
        fetchRequest()
    }
    const handleCalendarDown = () => {
        const currentDate = calendarDate;
        currentDate.setMonth(currentDate.getMonth() - 1)
        setCalendarDate(currentDate)
        fetchRequest()
    }

    const handlePostRequest = () => {
        const c = client()

        if (response.curriculums !== undefined) {
            setResponse(prevState => ({...prevState, loading: true}))
            const post: IPostCurriculums = {curriculums: []}

            // eslint-disable-next-line array-callback-return
            response.curriculums?.map(value => {
                post.curriculums.push({...value, lecture_date: formatDate(value.lecture_date)})
            })
            c.post<ICurriculums | null>('curriculums/update', post)
                .then(() => {
                    fetchRequest()
                })
                .catch((error: AxiosError) => {
                    handleError(error)
                })

        }
    }

    return {
        ...response,
        fetchRequest,
        calendarDate,
        setCalendarDate,
        handlePostRequest,
        handleCalendarUP,
        handleCalendarDown
    }
};
export default useFetchAttendances;