const useToken = () => {
    const setToken = (token: string): void => {
        localStorage.setItem('token', token)

    }
    const getToken = (): string | undefined => {
        const token = localStorage.getItem('token')
        if (token === null) return ''

        return token
    }

    const removeToken = (): void => {
      localStorage.removeItem('token')
    }

    return {setToken, getToken,removeToken}
}
export default useToken