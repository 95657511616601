const useDate = () => {

    const dateTimeJp = (dateObj: Date) => `${dateObj.getMonth() + 1}月${dateObj.getDate()}日 ${dateObj.getHours()}時${dateObj.getMinutes()}分`

    const dateJp = (dateObj: Date) => `${dateObj.getMonth() + 1}月${dateObj.getDate()}日`

    const dateEn = (dateObj: Date) => `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`

    const isNullDate = (dateObj: Date) => dateObj.getTime() <= 0


    return {dateTimeJp, dateJp, isNullDate,dateEn}
}
export default useDate
