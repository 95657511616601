import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react";
import {
	Alert,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	TextField,
	Typography
} from "@mui/material";
import {ICurriculum} from '../../Types/Response';
import SecondButton from '../atom/Buttons/SecondButton';
import DefaultButton from '../atom/Buttons/DefaultButton';
import ThirdButton from "../atom/Buttons/ThirdButton";
import useFileUpload from "../../Hooks/useFileUpload";
import Conf from "../../Types/Conf";
import { MessageContext } from "../MessageContext";

type Props = {
	isOpen: boolean
	curriculum: ICurriculum
	onClose: Dispatch<SetStateAction<boolean>>
	handleChangeCurriculums: (cCurriculum: ICurriculum) => void

}
const SpecialCurriculumEdit: FC<Props> = ({isOpen, curriculum, onClose, handleChangeCurriculums}) => {
	const [editTitle, setEditTitle] = useState<string>(curriculum.title)
	const {handleCurriculumFilePost,handleCurriculumFileDelete,curriculumFile} = useFileUpload()
	const messageContext = useContext(MessageContext)

	const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {files} = e.target
		if (files === null) return
		handleCurriculumFilePost({curriculum_id: Number(curriculum.id), file: files[0]})
		
	}
	const handleUrlDelete = () => {
		handleCurriculumFileDelete(curriculum.id)
		handleChangeCurriculums({...curriculum, url: ""})
	}
	const handleChangeSpecialCurriculum = () => {
		handleChangeCurriculums({...curriculum,lecture_id:7, title: editTitle})
		onClose(false)
	}
	// todo file Upload
	// todo layout
	const handleChangeTitle = (title: string) => {
		setEditTitle(title)
	}
	useEffect(() => {
		handleChangeCurriculums({...curriculum, title: editTitle})
			handleChangeCurriculums({...curriculum, url: curriculumFile.url})

		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[curriculumFile])
	//

	return (
		<>
			<Dialog open={isOpen} onClose={() => onClose(false)}>
				<DialogTitle>特別授業編集</DialogTitle>
				<DialogContent>
					{messageContext.message.text.length > 0 && (
						<Alert
							color={messageContext.message.color}
							sx={{ my: 2 }}
							onClose={() => {
								messageContext.handleSetMessage('');
							}}
						>
							{messageContext.message.text}
						</Alert>
						)}

					<Stack direction={{sm: "column", xs: 'row'}} spacing={{md: 2, xs: 4}}>
						<TextField
							margin="dense"
							id="tel"
							label="授業タイトル"
							type="text"
							fullWidth
							// disabled={!isTitle}
							variant="standard"
							value={editTitle}
							onChange={(event) => {
								handleChangeTitle(event.target.value)
							}}
						/>
						{curriculum.url ?
							<Typography><a href={`${Conf.host}/curriculums/download/${curriculum.id}`}>カリキュラムファイル </a><ThirdButton
								label="削除" onClick={handleUrlDelete}/></Typography> :
							 !curriculumFile.isUpload && curriculumFile.url === '' && <Button variant="contained"
											component="label"

							>
								Upload File
								<input
									type="file"
									onChange={onChangeFile}
									hidden
								/>
							</Button>}
					</Stack>
				</DialogContent>
				<DialogActions>
					<SecondButton onClick={() => onClose(false)} label="キャンセル"/>
					<DefaultButton onClick={handleChangeSpecialCurriculum} label="保存"/>
				</DialogActions>
			</Dialog>

		</>
	)
}
export default SpecialCurriculumEdit