import React, {createContext, Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react';

type TColor = 'success' | 'info' | 'warning' | 'error';
interface InterfaceMessage {
    text:string
    color:TColor
}

type ContextType = {
    message: InterfaceMessage;
    handleSetMessage: (message: string,color?:TColor) => void;
    setMessage?: Dispatch<SetStateAction<string>>;
}

export const MessageContext = createContext({} as ContextType);

export const useMessage = () => {
    const [message, setMessage] = useState<InterfaceMessage>({text:'',color:'info'});

    const handleSetMessage = useCallback((text: string,color:TColor = 'info') => {
        setMessage({text,color});
    }, []);
    const setContextError = (userMessage: string,color:TColor = 'info') => {

        setMessage({text:userMessage,color});
    };

    const setContextErrorDone = () => {
        setMessage({text:'',color:'info'});
    };

    return {
        message,
        handleSetMessage,
        setContextErrorDone,
        setContextError,
        setMessage
    };
};
type Props = {
    children: React.ReactNode
}
export const MessageProvider: React.FC<Props> = ({children}) => {
    const {message, handleSetMessage} = useMessage();
    const value = useMemo(() => ({message, handleSetMessage}), [handleSetMessage, message])

    return (
        <MessageContext.Provider value={value}>
            {children}
        </MessageContext.Provider>
    );
};