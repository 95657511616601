import { useContext, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import useHttp from 'Hooks/useHttp';
import { useError } from 'Components/ErrorContext';
import { useNavigate } from 'react-router';
import { MessageContext } from '../Components/MessageContext';

export interface ICurriculumBook {
  id: string;
  lectureDate: Date;
  lectureDateJp: string;
  lectureName: string;
  serviceLimit: string;
  bookCount: string;
  cancelCount: string;
  bookStatus: string;
  bookTime: string;
}
type TWeeks = Array<Array<ICurriculumBook>>;

interface IResponse {
  data: { studentName: string; curriculums: ICurriculumBook[]; weeks: TWeeks };
  error: AxiosError | null;
  isLoading: boolean;
}

const useFetchStudentBook = () => {
  const messageContext = useContext(MessageContext);
  const navigate = useNavigate();
  const { setContextError } = useError();
  const [response, setResponse] = useState<IResponse>({
    data: {
      studentName: '',
      curriculums: [],
      weeks: [],
    },
    error: null,
    isLoading: false,
  });
  const { client } = useHttp();

  const handleError = useMemo(
    () => (reason: AxiosError) => {
      setResponse((prevState) => ({ ...prevState, isLoading: false }));
      if (reason?.response?.status === 401) {
        setContextError(401, '再ログインが必要です');
      }
      if (reason?.response?.status === 500) {
        setContextError(500, 'サーバに不具合が起きています');
      }
    },
    [setContextError],
  );
  const newCurriculumBook = (targetDate: Date): ICurriculumBook => ({
    id: '',
    lectureDate: targetDate,
    lectureDateJp: '',
    lectureName: '',
    serviceLimit: '',
    bookCount: '',
    cancelCount: '',
    bookStatus: '',
    bookTime: '',
  });

  const createWeeks = (curriculums: ICurriculumBook[]) => {
    const weeks: TWeeks = [];

    const current = curriculums.concat();
    const sDay = current[0].lectureDate.getDay();
    const startDay = new Date(current[0].lectureDate);
    for (let i = 0; i <= sDay - 1; i += 1) {
      startDay.setDate(startDay.getDate() - 1);
      current.unshift(newCurriculumBook(new Date(startDay)));
    }
    const eDay = curriculums[curriculums.length - 1].lectureDate.getDay() + 1;
    const endDay = new Date(current[current.length - 1].lectureDate);
    for (let i = eDay; i < 7; i += 1) {
      endDay.setDate(endDay.getDate() + 1);
      current.push(newCurriculumBook(new Date(endDay)));
    }

    current.forEach((value, index) => {
      if (index === 0 || index % 7 === 0) {
        weeks.push([]);
      }
      weeks[weeks.length - 1].push(value);
    });

    return weeks;
  };
  const fetchRequest = (studentId: string, year: string, month: string) => {
    setResponse((prevState) => ({ ...prevState, isLoading: true }));
    const c = client();
    c.get<{ studentName: string; curriculums: ICurriculumBook[] }>(
      `student/books/${studentId}/${year}/${month}`,
    )
      .then((res) => {
        const curriculums: Array<ICurriculumBook> = [];
        res.data?.curriculums.forEach((value) => {
          curriculums.push({
            ...value,
            lectureDate: new Date(value.lectureDate),
          });
        });

        setResponse({
          data: {
            studentName: res.data.studentName,
            curriculums,
            weeks: createWeeks(curriculums),
          },
          error: null,
          isLoading: false,
        });
      })
      .catch((reason: AxiosError) => {
        if (reason?.response?.status === 404) {
          navigate('/students/');
          messageContext.handleSetMessage(
            '利用者情報が見つかりませんでした。',
            'warning',
          );
          setResponse((prevState) => ({ ...prevState, isLoading: false }));
        } else {
          handleError(reason);
        }
      });
  };

  return {
    weeks: response.data.weeks,
    isLoading: response.isLoading,
    studentName: response.data.studentName,
    curriculums: response.data.curriculums,
    setResponse,
    fetchRequest,
  };
};

export default useFetchStudentBook;
