import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import HomeIcon from '@mui/icons-material/Home';
import {useNavigate} from 'react-router';
import {ListItemIcon, ListItemText, ListItem, Drawer, Divider, List, ListItemButton} from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Box from "../atom/Box";
import useToken from "../../Hooks/useToken";

type Props = {
    open: boolean;
    onClose: () => void;
};

const DropMenu = (props: Props) => {
    const {open, onClose} = props
    const navigate = useNavigate();
    const {removeToken} = useToken()
    const handleGotoPage = (url: string) => {
        navigate(url)
        onClose()

    }
    const handleLogout = () => {
        removeToken()
      navigate('/login')
    }
    const today = new Date()

    return (
        <Drawer open={open} onClose={onClose}>
            <Box sx={{width: 250}} role="presentation">
                <List>

                    <ListItem disablePadding onClick={() => handleGotoPage('/')}>
                        <ListItemButton>
                            <ListItemIcon>
                                <HomeIcon/>
                            </ListItemIcon>
                            <ListItemText primary="ダッシュボード"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding onClick={() => handleGotoPage('/students')}>
                        <ListItemButton>
                            <ListItemIcon>
                                <PeopleAltIcon/>
                            </ListItemIcon>
                            <ListItemText primary="利用者管理"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding
                              onClick={() => handleGotoPage(`/books/${today.getFullYear()}/${today.getMonth() + 1}`)}>
                        <ListItemButton>
                            <ListItemIcon>
                                <CalendarMonthIcon/>
                            </ListItemIcon>
                            <ListItemText primary="予約管理"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding onClick={() => handleGotoPage('/curriculums')}>
                        <ListItemButton>
                            <ListItemIcon>
                                <FolderSharpIcon/>
                            </ListItemIcon>
                            <ListItemText primary="カリキュラム管理"/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding onClick={() => handleLogout()}>
                        <ListItemButton>
                            <ListItemIcon>
                                <FolderSharpIcon/>
                            </ListItemIcon>
                            <ListItemText primary="ログアウト"/>
                        </ListItemButton>
                    </ListItem>
                </List>

                {/*    <List> */}
                {/*      {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => ( */}
                {/*    <ListItem key={text} onClick={props.onClose}> */}
                {/*  <ListItemIcon> */}
                {/*    {index % 2 === 0 ? <Inbox /> : <Mail />} */}
                {/*  </ListItemIcon> */}
                {/*  <ListItemText primary={text} /> */}
                {/*  </ListItem> */}
                {/* ))} */}
                {/*  </List> */}
                <Divider/>
            </Box>
        </Drawer>
    );
};
export default DropMenu