import { useContext, useEffect,  useState } from "react";
import { AxiosError } from "axios";
import { TStudent } from "Types/TStudent";
import useHttp from "Hooks/useHttp";
import { useNavigate } from "react-router";
import { MessageContext } from "../Components/MessageContext";
import useHandleError from "./useHandleError";

export interface IResponse {
    data: { message: string | null, student: TStudent | null };
    isLoading: boolean;
}

const useFetchStudentDetail = (id: number) => {
    const messageContext = useContext(MessageContext)
    const {handleError} = useHandleError()
    const [response, setResponse] = useState<IResponse>({data: {message: '', student: {id:0,name:'',email:'',password:'',retype_password:'',change_password:true}}, isLoading: false})
    const {client} = useHttp()

    const navigate = useNavigate();

    const fetchRequest = (requestId: number) => {
        setResponse(prevState => ({...prevState, isLoading: true}))
        const c = client()
        c.get<{ student: TStudent, message: string }>(`student/store/${requestId}`)
            .then((res) => {
                setResponse({
                    data: {student: res.data.student, message: res.data.message},
                    isLoading: false
                })
            })
            .catch((reason: AxiosError) => {
              if (reason?.response?.status === 404) {
                  navigate('/students/')
                  messageContext.handleSetMessage( '利用者情報が見つかりませんでした。','warning')
                  setResponse({isLoading: false, data: {message: '', student: null}, })

                }else {
                  handleError(reason)
                }
              }
            )
    }

    // const handleErrorClear = () => {
    //     setErrorMessage({hasErr: false, text: ''})
    // }
    const handleUpdateRequest = (postData: Omit<TStudent, 'id'>) => {
        setResponse(prevState => ({...prevState, isLoading: true}))
        const idStr = id
        const c = client()
        c.put<{ student: TStudent, message: string }>(`student/update/${idStr}`, postData)
            .then((res) => {
                setResponse({
                    data: {student: res.data.student, message: res.data.message},
                    isLoading: false
                })
                messageContext.handleSetMessage('利用者情報を更新しました。')
            })
            .catch((reason: AxiosError) => {
                    handleError(reason)
                }
            )
    }

    const handlePostAddRequest = (postData: Omit<TStudent, 'id'>) => {
        setResponse(prevState => ({...prevState, isLoading: true}))
        const c = client()

        c.post<{ student: TStudent, message: string }>('student/create', postData)
            .then((res) => {
                setResponse({
                    data: {student: res.data.student, message: res.data.message},
                    isLoading: false
                })
                messageContext.handleSetMessage('利用者情報を作成しました。')
                navigate((`/student/${res.data.student.id}`))
                // callback()

            })
            .catch((reason: AxiosError) => {
                    handleError(reason)
                }
            )
    }

    const handleDeleteRequest = (postId: string, callback: () => void) => {
        setResponse(prevState => ({...prevState, isLoading: true}))
        const idStr = response.data.student?.id ? response.data.student.id : 0
        const c = client()
        c.delete<{ message: string }>(`student/destroy/${idStr}`)
            .then(() => {
                callback()
            })
            .catch((reason: AxiosError) => {
                handleError(reason)
            })

    }
    const handleChange = (key: 'id' | 'name' | 'kana' | 'sex' | 'tel' | 'birthday' | 'goAt', value: string) => {
        const {data} = response

        if (data.student) data.student = {...data.student, [key]: value};
        setResponse(prevState => ({...prevState, data}))
        // handleErrorClear()
    }

    useEffect(() => {
        if (id > 0) fetchRequest(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        ...response,
        setResponse,
        handleDeleteRequest,
        handlePostAddRequest,
        handleChange,
        handleUpdateRequest,
    }
};

export default useFetchStudentDetail