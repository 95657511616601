import {FC} from "react";
import {ICurriculum} from "Types/Response";
import {Grid, Stack, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import CellDateHead from "./CellDateHead";
import CellDay from "../atom/CellDay";
import SecondButton from "../atom/Buttons/SecondButton";

type Props = {
    curriculum: ICurriculum,
    currentMonth: number,
}


const BookCell: FC<Props> = ({curriculum, currentMonth}) => {
    const navigate = useNavigate()
    const location = useLocation()

    return (

        <>
            <CellDay isMobileDisplay={currentMonth === curriculum.lecture_date.getMonth()}
                     bookCount={curriculum.books.length}
            uniqueKey={curriculum.id} key={curriculum.id}>
                {currentMonth === curriculum.lecture_date.getMonth() ?
                    (
                        <>
                            <Grid container alignItems="center">
                                <Grid item xs={12}>
                                    <Stack direction={{sm: "column", xs: 'column'}} spacing={{md: 2, xs: 4}}>
                                        <CellDateHead date={curriculum.lecture_date}/>
                                        <p>{curriculum.name}{curriculum.lecture_id === 7 && curriculum.title}</p>
                                        {curriculum.lecture_id > 1 && (<>
                                            <Typography>提供数：{curriculum.service_limit}<br/>
                                                予約数：{curriculum.books.filter((v) => v.books_rank <= curriculum.service_limit).length}<br/>
                                                キャンセル：{curriculum.books.filter((v) => v.books_rank > curriculum.service_limit).length}</Typography>


                                            <p>
                                                <SecondButton name={`day${curriculum.lecture_date.getDate()}`}
                                                              onClick={() => (navigate(`/book/${curriculum.id}`,{state:location}))}
                                                              label="詳細"/>
                                            </p>
                                        </>)}
                                    </Stack>
                                </Grid></Grid>
                        </>) : null
                }
            </CellDay>
        </>
    )
}
export default BookCell