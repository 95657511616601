import { AxiosError } from "axios";
import { useContext, useMemo } from "react";
import { MessageContext } from "../Components/MessageContext";

const useHandleError = () => {
    const messageContext = useContext(MessageContext)

    const handleError = useMemo(() => (reason: AxiosError) => {
        if (reason?.response?.status === 401) {
            messageContext.handleSetMessage(  '再ログインが必要です')
        }
        if (reason?.response?.status === 500) {
            messageContext.handleSetMessage( '申し訳ありません。サーバに不具合が発生しました。管理者に連絡をお願いします。','error')
        }
    }, [messageContext])

    return {handleError}
}

export default useHandleError;