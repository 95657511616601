import React, {FC} from 'react';
import {styled, TableCell} from '@mui/material'
import {orange} from '@mui/material/colors';
import {TableCellProps} from '@mui/material/TableCell/TableCell';

type styleProp = {
    display: 'flex' | 'none'
}


const SCellDay = styled(TableCell)<styleProp>(({display}) => ({
    textAlign: 'center',
    // width: '14.5%',
    verticalAlign: 'top',
    border: '1px solid #eee',
    overflow: 'hidden',

    padding: '2enm0.5em',
    '@media screen and (max-width: 768px) ': {
        border: '1px solid #eee',
        display,
        width: '90%',
    }
}))

type CellProps = Pick<TableCellProps, 'children'>

interface Props extends CellProps {
    isMobileDisplay: boolean
    bookCount: number
    uniqueKey:string
}

const CellDay: FC<Props> = ({uniqueKey,bookCount = 0, isMobileDisplay, children}) => {
    const selectClasses = (): string => {
        if (bookCount > 10) return orange['100']
        if (bookCount > 5) return orange['50']

        return '#fff'
    }

    return <SCellDay key={uniqueKey} display={isMobileDisplay ? 'flex' : 'none'}
                     style={{backgroundColor: selectClasses()}}>{children}</SCellDay>
}
export default CellDay
