import {useState} from "react";
import TimerA from "../atom/TimerA";
import Card from "../atom/Card";


const TestPage = () => {
    const [count, setCount] = useState<number>(5);
    const handleSet = (n:number) => setCount(n)

    return <>
        {count}<TimerA sec={count} setNum={setCount}/>
        <Card onSelect={()=>handleSet}/></>
}

export default TestPage