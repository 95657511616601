import {useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import useHttp from 'Hooks/useHttp';
import {ICurriculum} from "Types/Response";
import {TAtendanceCardStudent} from 'Types/TStudent';
import {TOffice} from "Types/TOffice";
import {useError} from "Components/ErrorContext";

export interface IHearing {
    curriculum: ICurriculum | undefined,
    students: TAtendanceCardStudent[]
    isLoading: boolean;
    office: TOffice | undefined
}

type TPostUpdate = {
    attendanceId: number
    interview: string
    commentStaffId: number | null

}

const useFetchHearingSheet = (id: number) => {
    const {setContextError} = useError()
    const [response, setResponse] = useState<IHearing>({
        isLoading: true,
        students: [],
        curriculum: undefined,
        office: undefined
    })
    const {client} = useHttp()

    const handleError = (reason: AxiosError) => {
        setResponse(prevState => ({...prevState, isLoading: false}))
        if (reason?.response?.status === 401) {
            setContextError(401, '再ログインが必要です')
        }
        if (reason?.response?.status === 500) {
            setContextError(500, 'サーバに不具合が起きています')
        }
    }

    const fetchRequest = (requestId: number) => {
        setResponse(prevState => ({...prevState, loading: true}))
        const c = client()
        c.get<IHearing | null>(`at/detail/${requestId}`)
            .then((res) => {
                const students: TAtendanceCardStudent[] = []
                res.data?.students?.forEach(at => {
                    if (at.status === 'attend') {
                        students[students.length] = {
                            studentId: Number(at.studentId),
                            attendanceId: Number(at.attendanceId),
                            name: at.name,
                            startTime: at.startTime ? new Date(at.startTime) : new Date(0),
                            endTime: at.endTime ? new Date(at.endTime) : new Date(0),
                            interview: at.interview ? at.interview : '',
                            commentStaffId: at.commentStaffId ? Number(at.commentStaffId) : 0,
                            isLoading: false,
                            status: at.status ? at.status : ''
                        }
                    }
                })
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setResponse(prevState => ({
                    ...prevState,
                    curriculum: res.data?.curriculum,
                    office: res.data?.office,
                    students,
                    isLoading: false
                }))
            })
            .catch((error: AxiosError) => {
                handleError(error)
            })
    }


    const handleUpdateCard = (studentId: number, commentStaffId: string, interview: string) => {
        const count = response.students.filter(value => value.studentId === studentId && (value.interview !== interview || value.commentStaffId !== parseInt(commentStaffId, 10))).length
        if (count === 1) setResponse(prevState => ({
            ...prevState,
            students: response.students.map(value => value.studentId === studentId ? {
                ...value,
                commentStaffId: parseInt(commentStaffId, 10),
                interview,
                isLoading: true
            } : value)
        }))

    }
    // const handleErrorClear = () => {
    //     setErrorMessage({hasErr: false, text: ''})
    // }

    const handleCardPostRequest = () => {
        const postData: TPostUpdate[] = response.students
            .filter(value => (value.isLoading)).map(val => ({
                attendanceId: val.attendanceId,
                interview: val.interview,
                commentStaffId: val.commentStaffId === 0 ? null : val.commentStaffId
            }))
        if (postData.length > 0) {
            const c = client()
            c.put<{ user: TAtendanceCardStudent, message: string }>(`at/hearingUpdate`, postData[0])
                .then(() => {
                    setResponse(prevState => ({
                        ...prevState,
                        students: response.students.map(value => value.isLoading ? {
                            ...value,
                            isLoading: !value.isLoading
                        } : value)
                    }))
                })
                .catch((reason: AxiosError) => {
                        handleError(reason)
                    }
                )
        }
    }

    useEffect(() => {
        if (id > 0) fetchRequest(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        handleCardPostRequest()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response.students])

    return {
        ...response,
        setResponse,
        handleUpdateCard,
        fetchRequest,
        handleCardPostRequest,
    }
};

export default useFetchHearingSheet;