import {FC} from "react";
import {Button, Grid, Stack} from "@mui/material";
import {useNavigate} from "react-router-dom";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HearingIcon from '@mui/icons-material/Hearing';
import CellDateHead from "Components/comm/CellDateHead";
import CellDay from "Components/atom/CellDay";
import {ICurriculum} from "Types/AttendancesResponse";

type Props = {
    curriculum: ICurriculum,
    currentMonth: number,
}


const AtendanceCell: FC<Props> = ({curriculum, currentMonth}) => {
    const navigate = useNavigate();

    return (

        <>
            <CellDay isMobileDisplay={currentMonth === curriculum.lecture_date.getMonth()}
                     bookCount={curriculum.attendances.length}
                     uniqueKey={curriculum.id} key={curriculum.id}>
                {currentMonth === curriculum.lecture_date.getMonth() ?
                    (
                        <>
                            <Grid container alignItems="center">
                                <Grid item xs={12}>
                                    <Stack direction={{sm: "column", xs: 'row'}} spacing={{md: 2, xs: 4}}>
                                        <CellDateHead date={curriculum.lecture_date}/>
                                        <p>{curriculum.name}{curriculum.lecture_id === 7 && curriculum.title}</p>
                                        {curriculum.lecture_id > 1 &&
                                            <>
                                                <Button startIcon={<DoneAllIcon/>}
                                                        onClick={() => navigate(`/attendance/${curriculum.id}`)}>出席管理</Button>
                                                <Button startIcon={<HearingIcon/>} color="secondary"
                                                        onClick={() => (navigate(`/hearingSheet/${curriculum.id}`))}>ヒアリング</Button>
                                            </>
                                        }
                                    </Stack>
                                </Grid></Grid>
                        </>) : null
                }
            </CellDay>
        </>
    )
}
export default AtendanceCell