import React, { FC, useContext, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Box } from '@mui/material';
import Header from 'Components/Organisms/Header';
import DropMenu from 'Components/Molecules/DropMenu';
import Footer from 'Components/Organisms/Footer';
import useToken from 'Hooks/useToken';
import { MessageContext } from './MessageContext';
import useAuth from '../Hooks/useAuth';

export interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  const { checkLoginStatus } = useAuth();
  let isAuthenticated = false;
  const { getToken } = useToken();
  if (getToken()) isAuthenticated = true;
  const navigate = useNavigate();
  const location = useLocation();
  const [dropMenuOpen, setDropMenuOpen] = useState(false);
  const toggleDropMenu = () => setDropMenuOpen(!dropMenuOpen);
  const messageContext = useContext(MessageContext);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (messageContext.message.color !== 'error') {
      const CTATimer = setTimeout(() => {
        messageContext.handleSetMessage('');
      }, 3000);

      return () => {
        clearTimeout(CTATimer);
      };
    }
  }, [messageContext]);

  useEffect(() => {
    async function checkStatus() {
      const isLoggedIn = await checkLoginStatus();
      console.log('isLogin',isLoggedIn)
      if (!isLoggedIn) {
        const currentUrl = location.pathname;
        navigate(`/login?from=${currentUrl}`);
      }
    }

    checkStatus().catch(() => {
      navigate('/login');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isAuthenticated ? (
    <>
      <Header onOpen={toggleDropMenu} />
      <DropMenu open={dropMenuOpen} onClose={toggleDropMenu} />
      <Box sx={{ m: 2, mb: 12 }}>
        {messageContext.message.text.length > 0 && (
          <Alert
            color={messageContext.message.color}
            sx={{ my: 2 }}
            onClose={() => {
              messageContext.handleSetMessage('');
            }}
          >
            {messageContext.message.text}
          </Alert>
        )}
        {children}
      </Box>
      <Footer />
    </>
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default PrivateRoute;
