/* eslint-disable */
import React, {FC, useEffect, useState} from "react";
import {Grid, MenuItem, TextField, Typography} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import DefaultButton from "Components/atom/Buttons/DefaultButton";
// eslint-disable-next-line import/no-cycle
import useFetchUserAbsent, {TAbsentPost} from "Hooks/useFetchUseAbsent";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import ja from 'date-fns/locale/ja'
import useDate from "../../Hooks/useDate";
import BackdropLording from "../comm/BackDrop";
import ThirdButton from "../atom/Buttons/ThirdButton";
import {useNavigate, useParams} from "react-router-dom";

type TAbsent = {
    reason: string
    staffId: string
    contactDate: Date
    contactPerson: string
    selfNote: string
    staffNote: string
    nextTime: string
}
const schema: yup.SchemaOf<TAbsent> = yup.object({
    reason: yup.string().required('理由を入力してください'),
    staffId: yup.string().required('対数者を入力してください'),
    contactPerson: yup.string().required('連絡者を入力してください'),
    selfNote: yup.string().required('本人コメントを入力してください'),
    staffNote: yup.string().required('対応者を入力してください'),
    nextTime: yup.string().required('次回予定を入力してください'),
    contactDate: yup.date().required('連絡日をを入力してください')
});

type TUrlParam = {
    id: string
}

const Absent: FC = () => {
    const {id} = useParams() as TUrlParam
    const [inputDate, setInputDate] = useState<Date | null>(null)
    const {
        student,
        curriculum,
        office,
        isLoading,
        isRedirect,
        fetchRequest,
        handleUpdateRequest,
        handleCancelRequest
    } = useFetchUserAbsent(Number(id))
    const {control, setValue, handleSubmit, formState: {errors}} = useForm<TAbsent>(
        {resolver: yupResolver(schema),}
    );
    const {dateJp, dateEn} = useDate()
    const navigate = useNavigate();

    const onSubmit: SubmitHandler<TAbsent> = (formData) => {
        handleUpdateRequest(formData)
    };

    const handleChangeStaff = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) setValue("staffId", event.target.value)


    }
    const handleCanCel = () => {
        handleCancelRequest()

    }
    useEffect(() => {
        if (inputDate) setValue('contactDate', inputDate)
    }, [inputDate])

    useEffect(() => {
            if (student) {
                // @ts-ignore
                setValue('contactDate', dateEn(student.contactDate))
                setValue('staffId', student.staffId)
            }
        },
        [student])

    useEffect(() => {
            if (isRedirect) {
                navigate(`/attendance/${curriculum?.id}`)
            }
        }
        , [isRedirect])


    useEffect(() => {
        if (id) fetchRequest(Number(id))
    }, [id])

    if (isLoading) return <BackdropLording showBackdrop={isLoading}/>

    // @ts-ignore
    return student && office ? <>
        <form onSubmit={(e) => handleSubmit(onSubmit)(e)} method="post">

            <Grid container alignItems="center" spacing={4} rowSpacing={4}>
                <Grid item xs={12}>
                    {curriculum && <Typography
                      variant="h4">{dateJp(curriculum?.lecture_date)} {curriculum.name} {student?.name}様
                        欠席処理</Typography>}
                </Grid>
                <Grid item xs={12} sx={{textAlign: 'right'}}>
                    {curriculum && <Typography><ThirdButton type="button" onClick={handleCanCel} name="cancel"
                                                            label="欠席を取り消す"/></Typography>}
                </Grid>
                <Grid item xs={1}>
                    <Typography>連絡日</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                    {/*　todo デフォルト値 */}
                    <Controller name="contactDate" key="contactDate" control={control}
                                defaultValue={student?.contactDate ? student.contactDate : new Date()}
                      // defaultValue={`${student?.contactDate.getFullYear()}-${student?.contactDate.getFullYear()}-${student?.contactDate.getDate()}`}
                                render={({field}) => <TextField {...field} error={'contactDate' in errors}
                                                                fullWidth
                                                                inputProps={{'data-testid':'contactDate'}}
                                                                type="date"
                                                                helperText={errors.contactDate?.message}/>}
                    />

                </Grid>
                <Grid item xs={12} sm={5}>
                    <Controller name="contactPerson" key="contactPerson" control={control}
                                defaultValue={student?.contactPerson}
                                render={({field}) => <TextField {...field} error={'contactPerson' in errors}
                                                                fullWidth
                                                                placeholder="連絡者"
                                                                label="連絡者"
                                                                autoFocus
                                                                inputProps={{"aria-label":'contactPerson'}}
                                                                helperText={errors.contactPerson?.message}/>}
                    />
                </Grid>

                <Grid item xs={2}>
                    <TextField sx={{width: '100%', fontSize: "80%", my: 2}}
                               key={student?.studentId}
                               error={'staffId' in errors}
                               name={`${student ? student.name : ''}`}
                               defaultValue={student?.staffId}
                               onChange={handleChangeStaff}
                               label="記入者"
                               select helperText={errors?.staffId?.message}
                    >
                        <MenuItem key="empty"
                                  value=""/>
                        {office?.staffs?.map((staff) => (
                          <MenuItem key={staff.id}

                                    value={staff.id.toString()}>{staff.name}</MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <Controller name="reason" key="reason" control={control} defaultValue={student?.reason}
                                render={({field}) => <TextField {...field} error={'reason' in errors} fullWidth
                                                                inputProps={{"aria-label":'reason'}}
                                                                placeholder="理由	"
                                                                label="理由	"
                                                                helperText={errors.reason?.message}/>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller name="selfNote" key="selfNote" control={control} defaultValue={student?.selfNote}
                                render={({field}) => <TextField {...field} error={'selfNote' in errors} fullWidth
                                                                placeholder="本人内容"
                                                                label="本人内容"
                                                                inputProps={{"aria-label":'selfNote'}}
                                                                multiline
                                                                rows="5"
                                                                helperText={errors.selfNote?.message}/>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller name="staffNote" key="staffNote" control={control} defaultValue={student?.staffNote}
                                render={({field}) => <TextField {...field} error={'staffNote' in errors} fullWidth
                                                                placeholder="スタッフ対応	"
                                                                label="スタッフ対応	"
                                                                multiline
                                                                inputProps={{"aria-label":'staffNote'}}
                                                                rows="5"
                                                                helperText={errors.staffNote?.message}/>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller name="nextTime" key="nextTime" control={control} defaultValue={student?.nextTime}
                                render={({field}) => <TextField {...field} error={'nextTime' in errors} fullWidth
                                                                placeholder="次回予定"
                                                                label="次回予定"
                                                                inputProps={{"aria-label":'nextTime'}}
                                                                helperText={errors.nextTime?.message}/>}
                    />
                </Grid>

                <Grid item xs={12} sx={{my: 2, textAlign: 'center'}}>
                    <DefaultButton type="submit" name="save" label="保存"/>
                </Grid>
            </Grid>
            </form>

    </> : <></>
}

export default Absent