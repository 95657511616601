import React, {FC, useContext, useEffect, useState} from 'react';
import {Alert, Box, Button, Grid, Stack, Typography} from '@mui/material';
import {useParams} from 'react-router';
import DefaultButton from 'Components/atom/Buttons/DefaultButton';
import {MessageContext} from 'Components/MessageContext';
import BackdropLording from 'Components/comm/BackDrop';
import AtendanceCell from "Components/comm/AtendanceCell";
import useFetchAttendances from "Hooks/useFetchAttendances";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FolderIcon from '@mui/icons-material/Folder';
import useAttendancesCalendar, {TWeeks} from "Hooks/useAttendancesCalendar";
import useFetchDownload from "Hooks/useFetchDownload";

type ICurriculumParam = {
    year: string
    month: string
}

const Attendances: FC = () => {
    const {year, month} = useParams() as ICurriculumParam

    const {
        loading,
        fetchRequest,
        curriculums,
        calendarDate,
        setCalendarDate,
        handleCalendarDown,
        handleCalendarUP
    } = useFetchAttendances(year, month)
    const {fetchServiceSheet,fetchHearingSheet,isDownloadLoading,mailSendingTitle} = useFetchDownload()
    const {createCurriculumCalendar} = useAttendancesCalendar()
    const [weeks, setWeeks] = useState<TWeeks>([[]])
    const [bookCount, setBookCount] = useState<number>(0)
    const messageContext = useContext(MessageContext)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleHearingSheetClick = () => {
        setAnchorEl(null);
        fetchHearingSheet()
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleServiceSheetClick = () => {
        setAnchorEl(null)
        fetchServiceSheet(calendarDate.toDateString())

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => fetchRequest(), [setCalendarDate])
    
    useEffect(()=>{
        if(mailSendingTitle) messageContext.handleSetMessage(`${mailSendingTitle}をメール送信しました。`)

    },[mailSendingTitle, messageContext])

    useEffect(() => {
        if (curriculums.length > 0) {
            messageContext.handleSetMessage('出欠を読み込みました。')

            setWeeks(createCurriculumCalendar(curriculums))
            setBookCount(() => {
                const c = curriculums.filter(value => value.lecture_id > 0)

                return c.length
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curriculums])

    useEffect(() => {
        if (loading) messageContext.handleSetMessage('')

    }, [loading, messageContext])

    if (loading || isDownloadLoading) return <><BackdropLording showBackdrop={loading || isDownloadLoading}/></>


    return <>
        <Box sx={{my: 3}}>
            <Grid container alignItems="center">
                <Grid item sm={7}>
                    <Typography variant="h5">
                        {calendarDate.getFullYear()}年{Number(calendarDate.getMonth()) + 1}月 出席管理
                    </Typography>
                </Grid>
                <Grid item sm={5} justifyContent="center">
                    <Stack justifyContent="flex-end" direction={{md: 'row', xs: 'row'}} alignItems="right"
                           spacing={3}>

                        <DefaultButton name="pre" onClick={handleCalendarDown} label="前月"/>

                        <DefaultButton name="next" onClick={handleCalendarUP} label="翌月"/>
                        {bookCount > 0 &&
                         <>   <Button
                                onClick={handleOpen}
                                endIcon={<KeyboardArrowDownIcon/>}
                            >
                                出力
                            </Button>
                            <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            >
                            <MenuItem onClick={handleServiceSheetClick} disableRipple>
                            <FolderIcon />
                            サービス提供実績記録表
                            </MenuItem>
                            <MenuItem onClick={handleHearingSheetClick} disableRipple>
                            <FolderIcon />
                            ヒアリングシート
                            </MenuItem>

                            </Menu>
                         </>
                        }
                    </Stack>
                </Grid>

        </Grid>
        </Box>
        {bookCount === 0 && <Alert severity="info">出欠が登録されていません。
            </Alert>}
        {bookCount > 0 && <table style={{width: '100%', border: '1px solid #eee', borderSpacing: '0'}}>
                {weeks.map((week) => (
                    <tr>
                        {week.map((day) => (
                            <AtendanceCell key={day.id} curriculum={day} currentMonth={calendarDate.getMonth()}/>

                        ))}
                    </tr>
                ))}
            </table>}
    </>

}
export default Attendances