import React, {FC} from "react"
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {styled, Typography} from '@mui/material';

const SAppBar = styled(AppBar)({
	top: "auto",
	bottom: 0,
  // padding:"1200px 0 0 0",
})
const SToolbar = styled(Toolbar)({
	top: "auto",
	bottom: 0,
	color: "white"

})
const Footer: FC = () => (
	<SAppBar color="primary">
		<SToolbar>
			<Typography style={{textAlign: "center", fontSize: "30%"}}>Copyright Man to Man Animo株式会社</Typography>
		</SToolbar>
	</SAppBar>
)

export default Footer
