/* eslint-disable */
import {yupResolver} from '@hookform/resolvers/yup'
import {FC, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import useFetchStudentDetail from 'Hooks/useFetchStudentDetail';
import {
    Box,
    Grid,
    Switch, TextField,
    Typography
} from '@mui/material';
import {TStudent} from 'Types/TStudent';
import * as yup from 'yup'
import React from 'react';
import {useNavigate} from 'react-router';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import DefaultButton from 'Components/atom/Buttons/DefaultButton';
import {MessageContext} from 'Components/MessageContext';
import SecondButton from 'Components/atom/Buttons/SecondButton';
import ThirdButton from 'Components/atom/Buttons/ThirdButton'
import UserDeleteDialog from 'Components/comm/User/UserDeleteDialog';
import BackdropLording from 'Components/comm/BackDrop';
import useHttp from "../../Hooks/useHttp";

type IBookDetailParam = {
    id: string
}
declare module 'yup' {
    interface StringSchema {
        unique(message: string): StringSchema;
    }
}


const StudentDetail: FC = () => {
    const {id} = useParams() as IBookDetailParam
    const {client} = useHttp()


    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const [isChangePass, setIsChangePass] = useState<boolean>(id ==='0')
    yup.addMethod(yup.string, 'unique', function(message) {
        return this.test('unique', message, function(value) {
            const c = client()

            return c.post('/student/checkEmail', {  email: value ,id:id} )
                .then((response) => {
                    return !response.data.exists;

                })
                .catch(() => {
                    return true
                });
        });
    });

    // @ts-ignore
    const schema: yup.SchemaOf<Omit<TStudent, 'id' | 'note'>> = yup.object({
        name: yup.string().required('利用者名を入力してください'),
        // kana: yup.string().required('利用者名 かなを入力してください'),
        email: yup.string().required('emailを入力してください').email('形式が違います。').unique('このメールアドレスは既に使用されています。'),
        // birthday: yup.string().required('生年月日を入力してください'),
        // school_name: yup.string().required('学校名を入力してください'),
        // school_year: yup.number().required('学年を入力してください'),
        // sex: yup.string().required('性別を入力してください'),
        // mobile_phone: yup.string().required('本人の連絡先を入力してください'),
        // emergency_contact: yup.string().required('緊急連絡先を入力してください'),
        // parent_name: yup.string().required('保護者名を入力してください'),
        // parent_contact: yup.string().required('保護者の連絡先を入力してください'),
        // service_no: yup.string().required('受給者証番号を入力してください'),
        // service_provision: yup.string().required('支給量を入力してください'),
        change_password:yup.boolean(),

        // service_limit: yup.string().required('支給期限を入力してください'),
        password: yup.string().when(
            'change_password',
            {is:true,
                then:yup.string().required('パスワードを入力してください。').min(6,'パスワードは6字以上です。')}),
        retype_password: yup.string().when(
            'change_password',
            {is:true,
                then:yup.string().oneOf([yup.ref('password')],'パスワードが一致していません')}),

    })
    const {
        data,
        isLoading,
        handleDeleteRequest,
        handleUpdateRequest,
        handlePostAddRequest
    } = useFetchStudentDetail(Number(id))
    const messageContext = useContext(MessageContext)
    const {control, setValue, handleSubmit, formState: {errors}} = useForm<TStudent>(
        {
            context: undefined,
            criteriaMode: undefined,
            delayError: 0,
            mode: undefined,
            reValidateMode: undefined,
            shouldFocusError: false,
            shouldUnregister: false,
            shouldUseNativeValidation: false,
            resolver: yupResolver(schema),defaultValues:{name:'',email:'',change_password:false,password:'',retype_password:'',note:''}}
    );
    const navigate = useNavigate();

    const createPassword = Math.random().toString(36).slice(-8);
    const handleDelete = () => {
        handleDeleteRequest(id, () => {
            messageContext.handleSetMessage('利用者情報を削除しました。')
            const CTATimer = setTimeout(() => {
                navigate('/students')
            }, 1000)
            return () => {
                clearTimeout(CTATimer);
            };

        })

    }
    // useEffect(() => {
    //     if (!isLoading && data.message === 'student update successfully') messageContext.handleSetMessage('利用者情報を更新しました。')
    //     if (!isLoading && data.message === 'student created successfully') messageContext.handleSetMessage('利用者情報を作成しました。')
    //
    // }, [data.message, isLoading, messageContext])

    useEffect(() => {
        if (data.student) {
            for (let [key, value] of Object.entries(data.student)) {
                    // @ts-ignore
                    setValue(key, value)
            }
            setValue('change_password',false)

        }
    }, [data.student]);

    if (isLoading) return <><BackdropLording showBackdrop={isLoading}/></>


    const onSubmit: SubmitHandler<TStudent> = formData => {
        messageContext.handleSetMessage("")
        const f = formData
        delete f.change_password
        delete f.retype_password
        if (id === '0') {
            handlePostAddRequest(f)
        } else {
            if (!isChangePass) delete f.password
            handleUpdateRequest(f)
        }
    };

    const handleChangePassword = () => {

        setIsChangePass(prevState => !prevState)
    }
    const handleCreatePassword = () => {
        const createPassword = Math.random().toString(36).slice(-8);

        setValue('password', createPassword)
        setValue('retype_password', createPassword)
    }
    if(data.student) return (
        <>
            <Box>
                <Grid container alignItems="center">
                    <Grid item xs={10}>
                        <Typography
                            variant="h5">利用者 {id === '0' ? '登録' : '編集'}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} justifyContent="flex-end">
                        <p style={{textAlign: 'right'}}>
                            <SecondButton name="back" onClick={() => navigate('/students')} label="戻る"/>
                        </p>
                        {Number(id) > 0 &&
                            <p style={{textAlign: 'right'}}><ThirdButton
                                name="delete"
                                onClick={() => setOpenDeleteDialog(true)}
                                label="削除"/></p>}
                    </Grid>

                </Grid>
            </Box>
            <UserDeleteDialog open={openDeleteDialog} onClose={setOpenDeleteDialog} onDelete={handleDelete}/>
            <form  onSubmit={(e) => handleSubmit(onSubmit)(e)} method="post">
                <Box>
                    <Grid container spacing={4} rowSpacing={4}>
                        <Grid item xs={12} sm={6}>
                            <Controller name="name" key="name" control={control} defaultValue={data.student?.name}
                                        render={({field}) => <TextField {...field} error={'name' in errors} fullWidth
                                                                        placeholder="利用者名"
                                                                        label="利用者名"
                                                                        autoFocus
                                                                        helperText={errors.name?.message}/>}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sm={4}>
                            <Controller name="email" key="email" control={control} defaultValue={data.student?.email}
                                        render={({field}) => <TextField {...field} error={'email' in errors} fullWidth
                                                                        placeholder="email"
                                                                        label="email"
                                                                        helperText={errors.email?.message}/>}
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <Typography
                                variant="h5">パスワード { id !== '0' &&
                              <Controller name="change_password" key="change_password" control={control}
                                          defaultValue={false}
                                          render={({field}) =>
                                    <Switch
                                      type="checkbox" name="change" aria-label="change"
                                      checked={isChangePass}

                                        onChange={(event)=>{
                                            field.onChange(event.target.checked)
                                            handleChangePassword()
                                        }}/>}
                              />}</Typography>
                        </Grid>
                        {isChangePass   && (<>
                            <Grid item sm={6} xs={12}>
                                <Controller name="password" key="password" control={control}
                                            defaultValue={createPassword}
                                            render={({field}) => <TextField {...field} error={'password' in errors}
                                                                            fullWidth
                                                                            placeholder="パスワード"
                                                                            label="パスワード"
                                                                            helperText={errors.password?.message}/>}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Controller name="retype_password" key="retype_password" control={control}
                                            defaultValue={createPassword}
                                            render={({field}) => <TextField {...field}
                                                                                error={'retype_password' in errors} fullWidth
                                                                            placeholder="パスワード(確認)"
                                                                            label="パスワード(確認)"
                                                                            helperText={errors.retype_password?.message}/>}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{my: 2, textAlign: 'right'}}>

                                <DefaultButton type="button" name="createPassword" onClick={handleCreatePassword} label="パスワード作成"/>
                            </Grid>
                        </>)}
                        <Grid item xs={12} sx={{my: 2, textAlign: 'center'}}>

                            <DefaultButton type="submit" name="save" label={id === '0' ? '登録' : '保存'}/>
                        </Grid>
                    </Grid>

                </Box>
            </form >

        </>
    );

    return <></>
};

export default StudentDetail
