import {FC} from "react";
import { Bar} from 'react-chartjs-2';
import {ICurriculum} from "Types/Response";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type props = {
    curriculums: ICurriculum[],
}

const DailyBar: FC<props> = ({curriculums}) => {
    const labels: string[] = []
    const dataset: number[] = []
    const borderColor: string[] = []
    const backgroundColor: string[] = []
    curriculums.forEach((curriculum, index) => {
            labels.push((index+1).toString())
            dataset.push(curriculum.books.length)
            backgroundColor.push('rgba(75, 192, 192, 0.2)')
            borderColor.push('rgba(75, 192, 192)')
        }
    )
    const data = {
        labels,
        datasets: [
            {
                label:"予約数",
                data: dataset,
                backgroundColor,
                borderColor,
                borderWidth: 1,
            }
        ]
    }

    return <Bar height="50" data={data}/>
}
export default DailyBar