import {useCallback, useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import {TClassStudent} from "Types/TStudent";
import useHttp from "Hooks/useHttp";
import useHandleError from "./useHandleError";


const useFetchStudents = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [students, setStudents] = useState<TClassStudent[]>([])
    const {client} = useHttp()
    const {handleError} = useHandleError()

    const fetchRequest = useCallback(() => {
        setIsLoading(true)
        const c = client()

        c.get<Array<TClassStudent> | null>('students')
            .then((response) => {
                setStudents(response.data ? response.data : [])
                setIsLoading(false)
            })
            .catch((reason: AxiosError) => {
                setIsLoading(false)
                handleError(reason)
            })
    }, [client, handleError])

    useEffect(() => {

        fetchRequest()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {students, setStudents, isLoading}
};
export default useFetchStudents;