import {Paper, styled, Typography} from '@mui/material';
import {FC} from 'react';
import {useNavigate} from 'react-router';
import DefaultButton from 'Components/atom/Buttons/DefaultButton';

const SBox = styled(Paper)(() => ({
  textAlign: 'center',
  minWidth: '80%',
  padding: 50
}));


const Error: FC = () => {
  const navigate = useNavigate()

  return (
    <SBox>
      <Typography variant="h5">申し訳ありません。サーバに不具合が発生しました。</Typography>
      <Typography><img src={`${process.env.PUBLIC_URL}/man-sorry.png`} alt="sorry" width={200}/></Typography>
      <Typography>仕事を止めてしまい、本当にごめんなさい。管理者に連絡をお願いします。</Typography>
      <Typography sx={{my:"3em"}}><DefaultButton label="ログイン画面" onClick={() => {
        navigate('/login')
      }}/></Typography>

    </SBox>
  )

}
export default Error