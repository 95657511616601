import React, {createContext, FC} from "react";
import {TLogin} from "../Types/Login";
import useAuth from "../Hooks/useAuth";

interface AuthUserProviderProps {
    children: React.ReactNode;

}
type OperationType = {
    loginRequest: (email: string, password:string) => Promise<TLogin>|null
    logout: () => void
    checkLoginStatus: () => Promise<boolean>
}
export const AuthOperationContext = createContext<OperationType>({
    loginRequest:()=>null,
    logout: ()  => null,
    checkLoginStatus: () => Promise.resolve(false),

})

const AuthUserProvider:FC<AuthUserProviderProps> = ({children}) => {
    // const authLogin = (accessToken:string):void=>{
    //     localStorage.setItem('user',accessToken)
    // }
    const {logout,checkLoginStatus,loginRequest} = useAuth()

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <AuthOperationContext.Provider value={{logout,checkLoginStatus,loginRequest}}>
            {children}
        </AuthOperationContext.Provider>
    )
}
export default AuthUserProvider
// export const useAuth = ()=> useContext(AuthOperationContext)