import React, {FC} from "react";
import {Button, styled} from "@mui/material";
import {teal} from '@mui/material/colors';
import {BaseButtonProps} from './DefaultButton';


const SecondButton: FC<BaseButtonProps> = ({onClick, label, type = 'button', name, disabled = false}) => {

    const SButton = styled(Button)({
        textAlign: 'center',
        padding: '0.5em 2em',
        color: '#fff',
        borderRadius: 10,
        backgroundColor: teal['600'],
        '&:hover': {
            backgroundColor: teal['500']
        }
    });
    let newName = name;
    if (name === undefined) newName = `button`;

    return (
        <SButton disabled={disabled} name={newName} aria-label={newName} type={type} onClick={onClick}>
            {label}
        </SButton>
    );

}
export default SecondButton
