import {useCallback} from "react";
import axios from "axios";
import {TLogin, TLoginResponse} from "Types/Login";
import Conf from "Types/Conf";
import useHttp from "./useHttp";
import useToken from "./useToken";

const useAuth = () => {
    const {client} = useHttp()
    const {setToken,removeToken} = useToken()
    const loginRequest = useCallback(async (email: string, password: string): Promise<TLogin> => {
        try {

            const res = await axios.post<TLoginResponse>(`${Conf.apiIrl}login`, {
                email,
                password
            }, {
                headers: {
                    "Access-Control-Allow-Origin": "http://localhost:3002",
                    "content-type": "application/json"
                }
            });

            return {isLogin: true, statusCode: 200, errorMessage: "", user: res.data}

        } catch (e: unknown) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 401 || e.response?.status === 422) {
                    return {
                        isLogin: false,
                        statusCode: 401,
                        errorMessage: "メールアドレスか、パスワードが違います。",
                        user: {access_token: '', id: 0}
                    }
                }
                if (e.response?.status === 500) {
                    return {
                        isLogin: false,
                        statusCode: 500,
                        errorMessage: "サーバに不具合があります。",
                        user: {access_token: '', id: 0}
                    }
                }

            }

            return {isLogin: false, statusCode: 200, errorMessage: "。", user: {access_token: '', id: 0}}
        }

    }, []);


    const logout = useCallback((): void => {
        removeToken()
    }, [removeToken])
    const checkLoginStatus = async (): Promise<boolean> => {
        const http = client();

        try {
            const res = await http.post<TLoginResponse>(`${Conf.apiIrl}me`);
            if (res.data.access_token){
                setToken(res.data.access_token)

                return true
            }

            return true;
        } catch (error) {
            return false;
        }
    };

    return {logout, checkLoginStatus, loginRequest}
}
export default useAuth