import {AxiosError} from "axios";
import {useMemo, useState} from "react";
import useHttp from "./useHttp";
import {useError} from "../Components/ErrorContext";

const useFetchDownload = () => {
    const {client} = useHttp()
    const {setContextError} = useError()
    const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false)
    const [mailSendingTitle, setMailSendingTitle] = useState<string|null>(null)
    const handleError = useMemo(() => (reason: AxiosError) => {

        if (reason?.response?.status === 401) {
            setContextError(401, '再ログインが必要です')
        }
        if (reason?.response?.status === 500) {
            setContextError(500, 'サーバに不具合が起きています')
        }
    }, [setContextError])

    const fetchServiceSheet = (sheetDate: string) => {
        const post = {date: sheetDate}
        setIsDownloadLoading(true)
        setMailSendingTitle(null)
        const c = client()
        c.post<{ message: string }>(`/at/serviceSheet`, post)
            .then(() => {
                setIsDownloadLoading(false)
                setMailSendingTitle("実績記録表")
            })
            .catch((reason: AxiosError) => {
                handleError(reason)
            })

        return false

    }

    const fetchHearingSheet = () => {
        setIsDownloadLoading(true)
        setMailSendingTitle(null)
        const c = client()
        c.post<{ message: string }>(`/at/hearingSheet`)
            .then(() => {
                setIsDownloadLoading(false)
                setMailSendingTitle("ヒアリングシート")
            })
            .catch((reason: AxiosError) => {
                handleError(reason)
            })

        return false

    }

    return {fetchServiceSheet, fetchHearingSheet, isDownloadLoading, mailSendingTitle}
}

export default useFetchDownload