import {FC} from "react";
import {useNavigate} from "react-router-dom";
import {Box, Button, Chip, CircularProgress, Grid, Paper, Stack, styled, TextField, Typography} from "@mui/material";
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import DefaultButton from "Components/atom/Buttons/DefaultButton";
import useDate from "Hooks/useDate";
import {TAtendanceCardStudent} from "Types/TStudent";
import ThirdButton from "Components/atom/Buttons/ThirdButton";
import {ICurriculum} from "Types/AttendancesResponse";

type Props = {
    curriculum: ICurriculum
    student: TAtendanceCardStudent
    timeFunction: (mode: 'update' | 'reset', studentId: number, key: 'startTime' | 'endTime', dateValue?: Date) => void
}
const SBox = styled(Paper)({
    padding: '2em',
    margin: '1em 0',
    width: '100%'
})
const STime = styled(Box)({
    textAlign: "center",
    width: "100%",
    alignItems: "center",
    margin: '1em 0'
})
const SButton = styled(Button)({
    backgroundColor: "white",
    boxShadow: "none",
    ":hover": {
        backgroundColor: "white",
        boxShadow: "none",
    }
})
const AtendanceCard: FC<Props> = ({student,curriculum ,timeFunction}) => {
    const today = new Date()
    today.setHours(0)
    today.setMinutes(0)
    today.setSeconds(0)

    const {isNullDate} = useDate()
    const navigate = useNavigate()
    // const [startTime,setStartTime]= useState<string>('00:00')
    const handleChangeDateToTime = (valueDate: Date | null) => {
        if(valueDate) return `${valueDate.getHours().toString().padStart(2, '0')}:${valueDate.getMinutes().toString().padStart(2, '0')}`

        return "00:00"
    };

    const timeUpdate = (time:string,mode:'startTime'|'endTime') => {
        const timeArray = time.split(':')
        const dateObj = new Date(curriculum.lecture_date.getFullYear(),curriculum.lecture_date.getMonth(),curriculum.lecture_date.getDate(),Number(timeArray[0]),Number(timeArray[1]))

        timeFunction('update',student.studentId,mode,dateObj)
    }
    const handleGOAbsent = () => {
        navigate(`/absent/${student.attendanceId}`)

    }

    // const handleChangeEnd = (newValue: Date | null) => {
    //     if (newValue) {
    //         timeFunction('update', student.studentId, "endTime", newValue)
    //     }
    // };
    const isFuture = () => today.getTime() <= curriculum.lecture_date.getTime() || student.startTime.getTime() >= 0
    const isToday = (day:Date) => today.getTime() <= (curriculum.lecture_date.getTime() &&  curriculum.lecture_date.getTime() <= today.getTime() + 86400000) || day.getTime() <= 0
    if (student.isLoading) return <SBox><Typography
        sx={{textAlign: "center", my: "2em"}}><CircularProgress/></Typography></SBox>

    return <SBox>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container alignItems="center">
                <Grid item xs={2}>
                   <Typography variant="h4">{student.name}様</Typography>
                </Grid>
                <Grid item xs={2}>

                    {student.status === 'attend' && <Box> <Chip label="出席" color="primary"  /></Box>}
                    {student.status === 'absence' && <Box><Chip label="欠席" color="secondary"  /></Box>}
                </Grid>
                <Grid item xs={4}>
                    <STime>
                        {isToday(student.startTime) &&  (student.status === 'attend'  || student.status === '')  && <DefaultButton label="入所" disabled={!isNullDate(student.startTime)} name={`attendButton${student.attendanceId}`}
                                                     onClick={() => timeFunction('update', student.studentId, "startTime", new Date())}/>}
                    </STime>
                    <STime>

                    {isFuture() && (student.status === 'absence' || student.status === '')  && <ThirdButton label="欠席処理" disabled={!isNullDate(student.startTime)}
                                                     onClick={() => handleGOAbsent()} name={`absencePageButton${student.attendanceId}`}/>}
                    </STime>
                    <STime>
                        <Stack
                            justifyContent="center"
                            gap={2}
                            flexDirection="row"
                            width={1.0}
                            flexWrap="wrap"
                        >
                            {!isNullDate(student.startTime) && (student.status === 'attend' || student.status === '') && <TextField
                                inputProps={
                                    {
                                        "aria-label" :`attendStartTime${student.attendanceId}`,
                                        "data-testid":`attendStartTextBox${student.attendanceId}`

                                    }
                                }
                                label="入所時間"
                                defaultValue={handleChangeDateToTime(student.startTime)}
                                onBlur={event => timeUpdate(event.target.value,"startTime")}
                                type="time"

                                />}

                            {!isNullDate(student.startTime)  && ( student.status === 'attend'  || student.status === '') && isNullDate(student.endTime) &&
                                <SButton onClick={() => timeFunction('reset', student.studentId, "startTime")}
                                         type="button"> <HighlightOffRoundedIcon
                                    onClick={() => timeFunction('reset', student.studentId, "endTime")}/></SButton>
                            }
                        </Stack>
                    </STime>
                </Grid>
                <Grid item xs={4}>
                    <STime>
                        {isToday(student.endTime)  && ( student.status === 'attend'  || student.status === '')  && <ThirdButton label="退所"
                                        onClick={() => timeFunction("update", student.studentId, "endTime", new Date())} name={`absenceButton${student.attendanceId}`}
                                        disabled={!(!isNullDate(student.startTime) && isNullDate(student.endTime))}/>}</STime>
                    <STime>
                        <Stack
                            justifyContent="center"
                            gap={2}
                            flexDirection="row"
                            width={1.0}
                            flexWrap="wrap"
                        >
                            {!isNullDate(student.endTime) && <> <TextField
                                inputProps={
                                    {
                                        "aria-label" :`attendEndTime${student.attendanceId}`,
                                        "data-testid":`attendEndTextBox${student.attendanceId}`

                                    }
                                }
                                label="退所時間"
                                defaultValue={handleChangeDateToTime(student.endTime)}
                                onBlur={event => timeUpdate(event.target.value,"endTime")}
                                type="time"
                                />
                                {!isNullDate(student.endTime) &&
                                    <SButton type="button"
                                             onClick={() => timeFunction('reset', student.studentId, "endTime")}><HighlightOffRoundedIcon/></SButton>}
                            </>}
                        </Stack>
                    </STime>
                </Grid>
            </Grid>

        </LocalizationProvider>
    </SBox>
}

export default AtendanceCard