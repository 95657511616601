import React, { FC } from 'react';
import {Button, ButtonProps, styled} from '@mui/material';
import { lightGreen } from '@mui/material/colors';

export type BaseButtonProps = ButtonProps & {label: string;};
const DefaultButton: FC<BaseButtonProps> = ({  onClick, label, type = 'button', name, disabled = false }) => {
  const SButton = styled(Button)({
    textAlign: 'center',
    padding: '0.5em 2em',
    color: 'white',
    borderRadius: 10,
    fontSize: '90%',
    background: lightGreen['800'],
    '&:hover': {
      backgroundColor: lightGreen['700'],
      // color: lightGreen['00']
    },
  });
  let newName = name;
  if (name === undefined) newName = `button`;

  return (
      <SButton disabled={disabled} name={newName} aria-label={newName} type={type} onClick={onClick}>
        {label}
      </SButton>
  );
};
export default DefaultButton;
