import React, { useEffect } from "react";

interface Props {
    onSelect: (choice: number | null) => void;
}

const Card: React.FC<Props> = ({onSelect}) => {

    useEffect(() => {
        const timeoutID = setTimeout(() => {
            onSelect(null);
        }, 1000);

        return () => {
            clearTimeout(timeoutID);
        };
    }, [onSelect]);

    return (
        <>
            {[1, 2, 3, 4].map((choice) => (
                <button
                    key={choice}
                    type="button"
                    data-testid={choice}
                    onClick={() => onSelect(choice)}
                >
                    {choice}
                </button>
            ))}
        </>
    );
};

export default Card;