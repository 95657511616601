import {FC, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router';
import {Alert, Box, Grid, Typography} from '@mui/material';
import SecondButton from '../atom/Buttons/SecondButton';
import BackdropLording from '../comm/BackDrop';
import AtendanceCard from "../comm/AtendanceCard";
import useFetchUserAtendance from "../../Hooks/useFetchUserAtendance"
import useDate from "../../Hooks/useDate";


// type PageProps = {} & RouteComponentProps<{id: string}>;
const AtendanceDetail: FC = () => {
    const {id} = useParams() as { id: string }
    const navigate = useNavigate();
    const {dateJp} = useDate()

    const {curriculum,students,fetchRequest, isLoading,handleUpdateTime} = useFetchUserAtendance(Number(id))
    const handGoToBack = () => {
        if (curriculum) {
            const date = new Date(curriculum.lecture_date)

            navigate(`/attendances/${date.getFullYear()}/${date.getMonth() + 1}`)
        }
    }


    useEffect(() => {
        fetchRequest(Number(id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (isLoading) return <><BackdropLording showBackdrop={isLoading}/></>

    return (
        <>
            <Box>
                <Grid container alignItems="center">
                    <Grid item xs={10}>
                        <Typography
                            variant="h5">{curriculum?.lecture_date && dateJp(new Date(curriculum.lecture_date))} {curriculum?.name} 出席管理</Typography>
                    </Grid>
                    <Grid item xs={2} justifyContent="flex-end">
                        <p style={{textAlign: 'right'}}>{curriculum?.lecture_date &&
                            <SecondButton onClick={handGoToBack} label="戻る"/>}</p>
                    </Grid>
                </Grid>
            </Box>
            {students.length === 0 &&
                <Alert sx={{mt: 2, mb: 4}} severity="info">利用者が見つかりませんでした。</Alert>
            }
            <Box sx={{marginBottom: '5en'}}>
                <Grid container justifyContent="flex-start" spacing={{xs: 1, md: 1}}>
                    {students.map((student)=> (
                        curriculum ? <AtendanceCard key={student.studentId} student={student} curriculum={curriculum} timeFunction={handleUpdateTime}/> :""
                    ))}

                </Grid>
            </Box>
        </>)
}
export default AtendanceDetail