import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
  palette: {
    primary: {
      main: '#93c746',
    },
    secondary: {
      main: '#d4b17d',
    },
    error: {
      main: '#c51162',
    },
    info: {
      main: '#3949ab',
    },
    success: {
      main: '#0d47a1',
    },
    text: {
      primary: 'rgba(80,79,79,0.87)',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
  },
});

export default Theme;
