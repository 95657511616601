import React, {FC, useState} from "react";
import {FormControlLabel, Grid, MenuItem, Stack, Switch, TextField, Typography} from "@mui/material";
import {lime} from "@mui/material/colors";
import SecondButton from '../atom/Buttons/SecondButton';
import SpecialCurriculumEdit from '../Organisms/SpecialCurriculumEdit';
import {ICurriculum, ILectureMaster} from '../../Types/Response';
import CellDateHead from './CellDateHead';
import CellDay from "../atom/CellDay";
import Conf from "../../Types/Conf";

type Props = {
    lectureMaster: Array<ILectureMaster> | undefined,
    curriculum: ICurriculum,
    currentMonth: number,
    handleChangeCurriculums: (cCurriculum: ICurriculum) => void
}


const CurriculumCell: FC<Props> = ({lectureMaster, curriculum, currentMonth, handleChangeCurriculums}) => {
    // console.log('CurriculumCell' + curriculum.lecture_date)
    const [isSpecialCurriculumEditWindow, setSpecialCurriculumEditWindow] = useState<boolean>(false)
    const handleChangeCurriculum = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Number(event.target.value) === 7) {
            setSpecialCurriculumEditWindow(true)
        } else {
            handleChangeCurriculums({...curriculum, lecture_id: Number(event.target.value)})
        }
    }
    const handleChangeCurriculumServiceLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeCurriculums({...curriculum, service_limit: Number(event.target.value)})
    }
    const serviceLimit = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]

    const handleChangeIsTime = (e: React.SyntheticEvent, isTimeValue: boolean) => {
        handleChangeCurriculums({...curriculum, is_time: isTimeValue ? 1 : 0})
    }


    return (
        <>
            <CellDay isMobileDisplay={currentMonth === curriculum.lecture_date.getMonth()}
                     bookCount={curriculum.books.length}
                     uniqueKey={curriculum.id} key={curriculum.id}>
                {currentMonth === curriculum.lecture_date.getMonth() ?
                    <>

                        <Grid container alignItems="center">
                            <Grid item xs={12}>
                                <Stack direction="column" spacing={{md: 2, xs: 4}}>
                                    <CellDateHead date={curriculum.lecture_date}/>
                                    <TextField sx={{width: '100%', fontSize: "80%", my: 2}}
                                               name={(curriculum.lecture_date.getDate() - 1).toString()}
                                               select
                                               value={curriculum.lecture_id}
                                               inputProps={{
                                                   "aria-label": `selectButton${curriculum.lecture_date.getDate().toString()}`,
                                                   "data-testid": `selectButtonId${curriculum.lecture_date.getDate().toString()}`
                                               }}
                                               onChange={handleChangeCurriculum}
                                    >
                                        {lectureMaster?.map((lecture) => (
                                            <MenuItem key={lecture.id}
                                                      value={lecture.id.toString()}>{lecture.name}</MenuItem>
                                        ))}
                                    </TextField>
                                    {curriculum.lecture_id > 1 &&
                                        <>
                                            <Typography
                                                variant="body1">サービス提供人数</Typography>
                                            <TextField sx={{width: '100%', fontSize: "80%", my: 2}}
                                                       name={(curriculum.lecture_date.getDate() - 1).toString()}
                                                       select
                                                       value={curriculum.service_limit.toString()}
                                                       inputProps={{
                                                           "aria-label": `selectButton${curriculum.lecture_date.getDate().toString()}`,
                                                           "data-testid": `selectButtonId${curriculum.lecture_date.getDate().toString()}`
                                                       }}
                                                       onChange={handleChangeCurriculumServiceLimit}
                                            >
                                                {serviceLimit?.map((value) => (
                                                    <MenuItem key={value.toString()}
                                                              value={value.toString()}>{value}名</MenuItem>
                                                ))}
                                            </TextField>
                                            {false && <FormControlLabel
                                                value={curriculum.is_time}
                                                control={<Switch sx={{color: lime['200']}}/>}
                                                label={<p style={{fontSize: "60%"}}>時間指定</p>}
                                                labelPlacement="start"
                                                onChange={(e: React.SyntheticEvent, isTimeValue: boolean) => {
                                                    handleChangeIsTime(e, isTimeValue)
                                                }}
                                            />}
                                        </>
                                    }

                                    {(curriculum.lecture_id === 7) &&
                                        <>
                                            <Typography>{curriculum.url ?
                                                <a href={`${Conf.host}/curriculums/download/${curriculum.id}`}>{curriculum.title}</a> : curriculum.title} </Typography>
                                            <SecondButton onClick={() => setSpecialCurriculumEditWindow(true)}
                                                          label="詳細"/>
                                        </>}
                                    <SpecialCurriculumEdit
                                        isOpen={isSpecialCurriculumEditWindow}
                                        curriculum={curriculum}
                                        onClose={setSpecialCurriculumEditWindow}
                                        handleChangeCurriculums={handleChangeCurriculums}
                                    />
                                </Stack></Grid></Grid>
                    </> : null
                }
            </CellDay>
        </>
    )
}
export default CurriculumCell