import {useEffect, useState} from 'react';
import {AxiosError} from 'axios';
import useHttp from "Hooks/useHttp";
import {ICurriculum} from "Types/Response";
import useHandleError from "./useHandleError";

type getResponse = {
    curriculums: ICurriculum[]
}
const useFetchHome = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [curriculums, setCurriculums] = useState<ICurriculum[]>([])
    const {client} = useHttp()
    const {handleError} = useHandleError()


    const fetchRequest = () => {
        setIsLoading(true)
        const c = client()

        c.get<getResponse>('dashBoard')
            .then((response) => {
                setCurriculums(response.data.curriculums)
                setIsLoading(false)
            })
            .catch((reason: AxiosError) => {
                setIsLoading(false)
                handleError(reason)
            })
    }

    useEffect(() => {
        fetchRequest()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {curriculums, isLoading}
};
export default useFetchHome;