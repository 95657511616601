import {FC} from "react";
import {Alert, Box, Grid, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router";
import useDate from "../../Hooks/useDate";
import SecondButton from "../atom/Buttons/SecondButton";
import useFetchHearingSheet from "../../Hooks/useFetchHearingSheet";
import BackdropLording from "../comm/BackDrop";
import HearingCard from "../comm/HearingCard";


const HearingSheet: FC = () => {
    const {id} = useParams() as { id: string }
    const navigate = useNavigate();
    const {dateJp} = useDate()
    const {curriculum, students, isLoading, handleUpdateCard, office} = useFetchHearingSheet(Number(id))
    const handGoToBack = () => {
        if (curriculum) {
            const date = new Date(curriculum.lecture_date)

            navigate(`/attendances/${date.getFullYear()}/${date.getMonth() + 1}`)
        }
    }

    if (isLoading) return <><BackdropLording showBackdrop={isLoading}/></>

    return <><Box>
        <Grid container alignItems="center">
            <Grid item xs={10}>
                <Typography
                    variant="h5">{curriculum?.lecture_date && dateJp(new Date(curriculum.lecture_date))} {curriculum?.name} ヒアリング</Typography>
            </Grid>
            <Grid item xs={2} justifyContent="flex-end">
                <p style={{textAlign: 'right'}}>{curriculum?.lecture_date &&
                    <SecondButton onClick={handGoToBack} label="戻る"/>}</p>
            </Grid>
        </Grid>
    </Box>
        {students.length === 0 &&
            <Alert sx={{mt: 2, mb: 4}} severity="info">利用者が見つかりませんでした。</Alert>
        }

        <Box sx={{marginBottom: '5en'}}>
            <Grid container justifyContent="flex-start" spacing={{xs: 1, md: 1}}>
                {students.length > 0 && office && students.map((student) => (
                    <HearingCard key={student.attendanceId} student={student} staffs={office.staffs}
                                 updateFunction={handleUpdateCard}/>

                ))}
            </Grid></Box></>

}
export default HearingSheet