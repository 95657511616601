import React, {FC} from 'react';
import {blue, green, red} from '@mui/material/colors';
import {styled, Typography} from "@mui/material";
import Conf from "Types/Conf";

const week = (d: Date) => {
    switch (d.getDay()) {
        case 0:
            return 'sun'
        case 6:
            return 'sat'
        default:
            return 'default'
    }
}

const color = {
    sun: red['50'],
    sat: blue['50'],
    default: green['600']
}
const backgroundColor = {
    sun: red['100'],
    sat: blue['100'],
    default: green['50']
}

type CellDateHeadProps = {
    date: Date
}

const CellDateHead: FC<CellDateHeadProps> = ({date}) => {
    const dayOfWeekStr = Conf.weeks

    const SDayHead = styled('div',{shouldForwardProp:(propName: PropertyKey) => propName !== 'targetDate' })<{ targetDate: Date }>(({targetDate}) => {
        const c = color[week(targetDate)]
        const b = backgroundColor[week(targetDate)]

        return {
            color: c,
            backgroundColor: b,
            margin: '0 0 0.5em 0',
            borderRadius: 10,
            padding: '5px',
        }
    })

    return (
        <SDayHead  targetDate={date}>
            <Typography>{date.getDate()}({dayOfWeekStr[date.getDay()]})</Typography>
        </SDayHead>
    )
}

export default CellDateHead
